import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { Timer, LotteryHeaderWrap, LotteryInfo, LotteryContainer, LotteryInfoWrap, LotteryTitle, LotteryDesc, ActWrap, ButtonWrapper, BuyTicketWrap, ImgWrap, ButtonWrap, Button, SelectWrap, SelectDescWrap } from './LotteryElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';
import { CodeWrap, NumberFormatCode } from '../Redeem/RedeemElements';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, Legend, Label } from 'recharts';
import Countdown, { zeroPad } from 'react-countdown';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../TaskView/TaskPageElements';
import { participantsTableHead } from '../../data/lotteryData';
import moment from 'moment';

const Lottery = () => {
    const [info, setInfo] = useState();
    const [diagram, setDiagram] = useState();
    const [table, setTable] = useState();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [apiError, setApiError] = useState('');
    const [clicked, setClicked] = useState(false);
    const [randomActive, setRandomActive] = useState(false);
    const [selectActive, setSelectActive] = useState(false);
    const [codeError, setCodeError] = useState('');
    const [complete, setComplete] = useState(false);
    const [ticketNumber, setTicketNumber] = useState();
    const renderer = ({ days, hours, minutes, seconds }) => {
	return <Timer>{zeroPad(days)} days + {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    useEffect(() => {
	const fetchLiveLotteryInfo = async () => {
	    try {
		const { data } = await axiosInstance.get('lottery/live/');
		setInfo(data.info);
		setDiagram(data.diagram);
		setTable(data.table);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
		setLoading(false);
	    };
	        fetchLiveLotteryInfo();
    }, []);
    const fetchLotteryRandom = async () => {
	setLoading2(true);
	try {
	    const { data } = await axiosInstance.post('lottery/random/', {
		lottery_id: info.id
	    });
	    setTicketNumber(data.ticket_number);
	    setComplete(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setCodeError(error.response.data.detail) : setCodeError(error.message);
	};
	setLoading2(false);
    };
    const fetchLotterySelect = async (code) => {
	setLoading2(true);
	try {
	    const { data } = await axiosInstance.post('lottery/selection/', {
		lottery_id: info.id,
		code: parseInt(code)
	    });
	    setTicketNumber(data.ticket_number);
            setComplete(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setCodeError(error.response.data.detail) : setCodeError(error.message);
	};
	setLoading2(false);
    };
    const handleClick = () => {
	setClicked(true);
    };
    const handleRandomClick = () => {
	setRandomActive(true);
	fetchLotteryRandom();
    };
    const handleSelectClick = () => {
	setSelectActive(true);
    };
    const handleChangeCode = (e) => {
	let code = e.target.value.replace(/ /g, '').replace('_', '');
	if (code.length === 6) {
	    fetchLotterySelect(code);
	};
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
		        {apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && !apiError && info !== undefined &&
		<Countdown
		    date={Date.now() + info.timer * 1000}
		    renderer={renderer}
		/>
	    }
	    {!loading && !apiError && info !== undefined &&
		<LotteryHeaderWrap>
		    <LotteryInfo>
			<LotteryContainer>
			    <LotteryInfoWrap>
			        <LotteryTitle>
			            <h2>tickets</h2>
			        </LotteryTitle>
			        <LotteryDesc>
			            <h2>{info.tickets}</h2>
			        </LotteryDesc>
			    </LotteryInfoWrap>
			    <LotteryInfoWrap>
			        <LotteryTitle>
			            <h2>participants</h2>
			        </LotteryTitle>
			        <LotteryDesc>
			            <h2>{info.participants}</h2>
			        </LotteryDesc>
			    </LotteryInfoWrap>
			    <LotteryInfoWrap>
			        <LotteryTitle>
			            <h2>reward</h2>
			       	</LotteryTitle>
			        <LotteryDesc>
			            <h2>{info.tickets * 100} pc</h2>
			        </LotteryDesc>
			    </LotteryInfoWrap>
			    <LotteryInfoWrap>
			        <LotteryTitle>
			            <h2>winners</h2>
			        </LotteryTitle>
			        <LotteryDesc>
			            <h2>{info.winners}</h2>
			        </LotteryDesc>
			    </LotteryInfoWrap>
			</LotteryContainer>
		    </LotteryInfo>
		    {diagram !== undefined &&
			<ActWrap>
			    <ResponsiveContainer width="95%" height={250}>
			        <AreaChart
			            data={diagram}
			            margin={{
					top: 10,
					right: 0,
					left: 0,
					bottom: 0
				    }}
			        >
			            <defs>
			                <linearGradient id="color_lottery" x1="0" y1="0" x2="0" y2="1">
			                    <stop offset="1%" stopColor="rgb(39, 117, 85)" stopOpacity={0.7} />
			                    <stop offset="99%" stopColor="rgb(39, 117, 85)" stopOpacity={0} />
			                </linearGradient>
			            </defs>
			            <XAxis dataKey='date' padding={{ right: 50 }} interval="preserveStartEnd" />
			            <YAxis padding={{ top: 10 }} interval={3}>
			                <Label angle={-90} position='insideLeft' offset={5}>
			                    New tickets
			                </Label>
			            </YAxis>
			            <Tooltip />
			            <Legend verticalAlign="top" iconSize={20} height={70} />
			            <Area
			                type="monotone"
			                dataKey='tickets'
			                stroke="rgba(39, 117, 85, 0.7)"
			                fill="url(#color_lottery)"
			            />
			        </AreaChart>
			    </ResponsiveContainer>
			</ActWrap>
		    }
		    <ButtonWrapper>
			{!clicked && !complete && !loading2 && codeError.length === 0 ?
			    <BuyTicketWrap clicked={clicked ? 1 : 0}>
				<ImgWrap>
				    <img src='https://icefaucet.com/media/help/blog/lottery.png' alt='lottery pic' />
				</ImgWrap>
				<ButtonWrap>
				    <Button onClick={handleClick}>Buy Ticket</Button>
				</ButtonWrap>
			    </BuyTicketWrap>
		        : clicked && !complete && !loading2 && codeError.length === 0 ?
			    <div>
				{!randomActive && !selectActive &&
				    <BuyTicketWrap>
					<ButtonWrap>
					    <Button onClick={handleRandomClick}>Random Ticket</Button>
					</ButtonWrap>
					<ButtonWrap>
					    <Button onClick={handleSelectClick}>Selectional Ticket</Button>
					</ButtonWrap>
				    </BuyTicketWrap>
				}
				{!randomActive && selectActive &&
				    <SelectWrap>
					<SelectDescWrap>
					    <p>Enter your lucky number as the ticket number in the lottery:</p>
					</SelectDescWrap>
					<CodeWrap>
					    <NumberFormatCode
						format={"#  #  #  #  #  #"}
						allowEmptyFormatting mask="_ "
						onChange={handleChangeCode}
					    />
					</CodeWrap >
				    </SelectWrap>
				}
			    </div>
			    : !complete && !loading2 && codeError.length > 0 ?
				<MsgWrap style={{ width: '80%' }} primary={0}>
				    <MsgText primary={0}>
				        {codeError}
				    </MsgText>
				</MsgWrap>
				: complete && !loading2 && codeError.length === 0 ?
				    <MsgWrap style={{ width: '80%' }} primary={1}>
				        <MsgText primary={1}>
				            Yout ticket number is {ticketNumber}. Get lucky.
				        </MsgText>
				    </MsgWrap> :
				    <Circle style={{ margin: 'auto', width: '25px', height: '25px' }} />
		        }
		    </ButtonWrapper>
		    {!loading && !apiError && info !== undefined && table.length > 0 &&
			<Table style={{ marginTop: '25px' }}>
			    <HeadWrap>
			        <HeadRow>
			            {participantsTableHead.map((header, index) => (
					<Header key={index}>
					    {header}
					</Header>
				    ))}
			        </HeadRow>
			    </HeadWrap>
			    <BodyWrap>
				{table.map((row, index) => (
				    <BodyRow key={index}>
					<Cell>
					    {index + 1}
					</Cell>
					<Cell>
					    {row.ticket_number}
					</Cell>
					<Cell>
					    {moment(row.created).fromNow()}
					</Cell>
				    </BodyRow>
				))}
			    </BodyWrap>
			</Table>
		    }
		</LotteryHeaderWrap>
	    }
	</>
    )
}
export default Lottery;

