import styled, { css } from 'styled-components';
import { AICard } from '../AccountInfo/AccountInfoElements';
import device from '../../constants/breakPoints';
import { AiFillThunderbolt } from 'react-icons/ai';

export const RefTurboTimer = styled.span`
    color: rgba(1, 219, 187, 1);
    font-size: clamp(0.75rem, 2.4vw, 0.85rem);
    letter-spacing: 1.3px;
`
export const IcedayTimer = styled.h1`
    color: rgba(29, 107, 187, 1);
    font-size: clamp(0.9rem, 2.4vw, 1rem);
    letter-spacing: 1.3px;
    margin-bottom: 65px;
`
export const NewWrap = styled.div`
    width: 75%;
    display: grid;
    grid-template-columns : 1fr 10fr 1fr;
    margin: 0 auto 15px auto;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0% , rgba(29, 107, 187, 0.5) 30%, rgba(39, 117, 85, 0.5) 70%, rgba(0, 0, 0, 0.5) 105%);
    border-radius: 5px;
    padding: 10px;
    @media screen and ${device.md} {
	width: 95%;
    }
    @media screen and ${device.sm} {
	grid-template-columns: 1fr;
	grid-row-gap: 15px;
	width: 65%;
    }
    @media screen and ${device.tn} {
	width: 100%;
    }
`
export const NewItemCard = styled.div`
    background: ${({ activate }) => (activate ? 'rgba(1, 219, 187, 0.55)' : 'rgba(1, 219, 187, 0)')};
    border: 2px solid rgba(1, 219, 187, 0.5);
    box-shadow: 0 1px 7px rgba(1, 219, 187, 0.7);
    border-radius: 5px;
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 5px;
`
export const LastCliam = styled.div`
    display: flex;
    margin-bottom: 65px;
    h3 {
	color: rgba(29, 107, 187, 1);
	font-size: clamp(0.85rem, 2.4vw, 0.95rem);
	letter-spacing: 1.3px;
    }
`
export const CircleWrap = styled.div`
    width: 50px;
    height: 50px;
    position: absolute;
    margin-top: 14px;
    background: rgba(29, 107, 187, 0.75);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const Title = styled.h3`
    color: ${({ activate }) => (activate ? 'rgba(1, 219, 187, 1)' : 'rgba(1, 219, 187, 0.6)')};
    font-size: 0.8rem;
    text-transform: uppercase;
    text-shadow: 0 0 1px ${({ activate }) => (activate ? 'rgba(1, 219, 187, 0.7)' : 'rgba(0, 0, 0, 0.6)')};
    letter-spacing: 0.7px;
    text-align: center;
`
export const NewItemWrap = styled.div`
    width: 63px;
    display: flex;
`
export const ResetInfoWrap = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px auto;
`
export const WraperItem = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px;
    @media screen and ${device.tn} {
        padding: 10px 2px;
    }
`
export const WrapItem = styled.div`
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    @media screen and ${device.lg} {
        grid-template-columns : 1fr 1fr;
        grid-row-gap: 30px;
    }
    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
        grid-row-gap: 30px;
    }
`
export const Timing = css`
    background: rgba(29, 107, 187, 0.75);
    border-radius: 3px;
    width: 70px;
    margin-top:7px;
    h3{
        color: #fff;
        font-size: 0.8rem;
        font-weight: 400;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        letter-spacing: 0.75px;
        text-align: center;
    }
`
export const TimingGift = styled.div`
    ${Timing}
`
export const TimingGift2 = styled.div`
    ${Timing}
    background: rgba(39, 117, 85, 0.75);
`
export const SpeedoMeterWrap = styled.div`
    display: flex;
    width: 180px;
    height: 200px;
    margin-top: 12px;
    span {
        position: relative;
        font-size: 0.8rem;
        color: #1d6bbb;
        top: 80px;
        right: 16px;
    }
`
export const SpeedoMeterAICard = styled(AICard)`
    &:nth-child(2n) {
        background: rgba(39, 117, 85, 0.5);
        border: 1px solid rgba(39, 117, 85, 0.3);
        box-shadow: 0 2px 10px rgba(39, 117, 85, 0.7);
    }
`
export const HeadWrap = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const ContentWrap = styled.div`
    display: flex;
    margin-bottom: 10px;

    h3{
        color: #fff;
        font-size: 0.85rem;
        font-weight: 400;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        letter-spacing: 0.75px;
        word-spacing: 1.75px;
        text-align: center;
    }
    span{
        margin-left: 5px;
        color: #01DBBB;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
        transform: translate(0, -11%);
    }
`
export const MembershipWrap = styled.div`
    display: grid;
    grid-template-columns : 1fr 6fr;
    width: 75%;
    border-radius: 5px;
    margin: 15px auto;
    background: linear-gradient(90deg, rgba(39, 117, 85, 0.5), rgba(29, 107, 187, 0.5));
    border-radius: 5px;
    border: 1px solid rgba(39, 117, 85, 0.3);

    @media screen and ${device.sm} {
        width: 80%;
        height: 70px;
        grid-template-columns : 1fr;
    }
    @media screen and ${device.tn} {
        width: 100%;
    }
`
export const ImgWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0;

    img {
        width: 60px;
    }

    @media screen and ${device.sm} {
        display: none;
    }
`
export const RangeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`
export const RangeWrap = styled.div`
    height: 10px;
    width: 90%;
    background: linear-gradient(90deg, rgba(39, 117, 85, 0.3), rgba(29, 107, 187, 0.3));
    border-radius: 5px;
    background: gray;
`
export const RangeFill = styled.div`
    width: ${({ membership }) => (membership === 0.75 ? '40%' : membership === 1.0 ? '100%' : '0')};
    height: 100%;
    background: rgba(1, 219, 187, 0.75);
    border-radius: 5px;
`
export const ValueWrap = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px auto 0 auto;

    h3{
        color: #fff;
        font-size: 0.85rem;
        font-weight: 400;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        letter-spacing: 0.75px;
        word-spacing: 1.75px;
    }
`
export const Value = styled.span`
    margin-left: 5px;
    color: #01DBBB;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    transform: translate(0, -11%);
`
export const MaxValue = styled.span`
    margin: 0 5px;
    font-size: 0.8rem;
    color: #1d6bbb;
`
export const ThunderContainer = styled.div`
    width: 80%;
    max-width: 300px;
    display: ${({ active }) => (active ? 'flex' : 'grid')};
    justify-content: center;
    grid-template-columns : 5fr 1fr;
    grid-column-gap: 10px;
    background: ${({ active }) => (active ? 'rgba(1, 219, 187, 0.55)' : 'rgba(29, 107, 187, 0.2)')};
    border: 1px solid rgba(1, 219, 187, 0.3);
    box-shadow: 0 0 10px rgba(1, 219, 187, 0.5);
    border-radius: 10px;
    padding: 20px;
    margin: auto;
    @media screen and ${device.md} {
	grid-template-columns : 1fr;
	grid-row-gap: 10px;
    }
    @media screen and ${device.sm} {
	width: 100%;
	padding: 20px 1px;
    }
    h2 {
	color: rgba(29, 107, 187, 1);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 0.8px;
	text-align: center;
    }
`
export const ThunderWrap = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    @media screen and ${device.md} {
	width: 100%;
	justify-content: center;
    }
`
export const ThunderItem = styled(AiFillThunderbolt)`
    color: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : '#c4c4c4')};
`
