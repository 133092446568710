export const withdrawalInfo = [
    {
	src: 'https://icefaucet.com/media/task/faucetpay.jpg',
        alt: 'faucetpay logo',
        title: 'your faucetpay wallet',
        min: '$0.25',
        max: '$5',
        fee: '0',
        limitation: 'once daily',
        type: 'F'
    },
    {
        src: require('../images/wallet.png').default,
        alt: 'wallet image',
        title: 'your cryptocurrency wallet',
        min: '$10',
        max: '$200',
        fee: '5%',
        limitation: 'once weekly',
        type: 'W'
    }
];
