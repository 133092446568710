import React from 'react';
import { ActContainer, ActWrap } from './ClickContainerElements';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Label
} from 'recharts';

const ClickContainer = ({ info }) => {
    return (
        <ActContainer primary={1}>
            <ActWrap primary={1}>
                <ResponsiveContainer width="95%" height={250}>
                    <AreaChart
                        data={info}
                        margin={{
                            top: 10,
                            right: 0,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <defs>
                            <linearGradient id="color_view_ads" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="1%" stopColor="rgb(29, 107, 187)" stopOpacity={0.7} />
                                <stop offset="99%" stopColor="rgb(29, 107, 187)" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="color_faucet" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="1%" stopColor="rgb(39, 117, 85)" stopOpacity={0.7} />
                                <stop offset="99%" stopColor="rgb(39, 117, 85)" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="color_mining" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="1%" stopColor="rgb(120, 116, 16)" stopOpacity={0.7} />
                                <stop offset="99%" stopColor="rgb(120, 116, 16)" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="color_banner_mining" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="1%" stopColor="rgb(90, 20, 200)" stopOpacity={0.7} />
                                <stop offset="99%" stopColor="rgb(90, 20, 200)" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey='date' padding={{ right: 50 }} interval="preserveStartEnd" />
                        <YAxis padding={{ top: 10 }} interval={3}>
                            <Label angle={-90} position='insideLeft' offset={5}>
                                Your Clicks
                            </Label>
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="top" iconSize={20} height={70} />
                        < Area
                            type="monotone"
                            dataKey='view ads'
                            stroke="rgba(29, 107, 187, 0.7)"
                            fill="url(#color_view_ads)"
                        />
                        <Area
                            type="monotone"
                            dataKey='faucet'
                            stroke="rgba(39, 117, 85, 0.7)"
                            fill="url(#color_faucet)"
                        />
                        <Area
                            type="monotone"
                            dataKey='surf mining'
                            stroke="rgba(120, 116, 16, 0.7)"
                            fill="url(#color_mining)"
                        />
                        <Area
                            type="monotone"
                            dataKey='banner mining'
                            stroke="rgba(90, 20, 200, 0.7)"
                            fill="url(#color_banner_mining)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </ActWrap>
        </ActContainer>
    )
}
export default ClickContainer;
