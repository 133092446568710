import styled, { css } from 'styled-components/macro';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import device from '../../constants/breakPoints';

export const Container = styled.section`
    position: relative;
    width: 100%;
    background: #f9f9f9;
    min-height: 580px;
    display: flex;
    justify-content: center;
    padding: 80px 10px 50px 10px;

    @media screen and ${device.tn} {
        padding: 80px 2px 50px 2px;
    };
`
export const IconWrapStyle = css`
    width: 50px;
    background : ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.5)' : 'rgba(29, 107, 187, 0.5)')};
    border-radius: 5px;
    border: 1px solid ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.7)' : 'rgba(29, 107, 187, 0.7)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(39, 117, 85, 1)' : 'rgba(29, 107, 187, 1)')};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin:25px 10px;
    transition: all 0.2s ease-in-out;

    @media screen and ${device.tn} {
        width: 35px;
    }

    &:hover {
        background: rgba(1, 219, 187, 0.5);
        transition: all 0.2s ease-in-out;  
    }
`
export const IconWrap = styled.div`
    ${IconWrapStyle}
    display: ${({ active }) => (active ? 'flex' : 'none')};
`
export const IconStyle = css`
    font-size: 2.2rem;
    color: #fff;
`
export const BackIcon = styled(IoIosArrowBack)`
    ${IconStyle}
`
export const Slider = styled.div`
    opacity: ${({ active }) => (active ? '1' : '0')};
    transition: all 0.99s ease-in-out;
`
export const InfoAdsWrap = styled.div`
    background: ${({ greenBg }) => (greenBg ? 'rgba(39, 117, 85, 0.5)' : 'rgba(29, 107, 187, 0.5)')};
    border-radius: 5px;
    border: 1px solid ${({ greenBg }) => (greenBg ? 'rgba(39, 117, 85, 0.7)' : 'rgba(29, 107, 187, 0.7)')};
    box-shadow: 0 0 10px ${({ greenBg }) => (greenBg ? 'rgba(39, 117, 85, 1)' : 'rgba(29, 107, 187, 1)')};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 75vh;
    min-height: 540px;
    margin: auto;
`
export const InfoAdsRow = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1.5fr;
    grid-column-gap: 25px;

    @media screen and ${device.md} {
        grid-template-columns : 1fr;
        grid-row-gap: 50px;
    }
`
export const TextAdsWrap = styled.div`
    padding: 15px;
    width: 100%;
    
    h4 {
        color: #fff;
        font-size: clamp(0.8rem, 2vw, 1.2rem);
        font-weight: 400;
        line-height: 1.3;
        text-shadow: 0 1px 3px #000;
    };

    @media screen and ${device.md} {
        text-align: center;
    };
`
export const ImgAdsWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    margin: auto;
`
export const Img = styled.img`
    width: 100%;
    padding: 60px 15px;

    @media screen and ${device.lg} {
        width: 45%;
    };

    @media screen and ${device.md} {
        width: 30%;
        padding: 0;
    };

    @media screen and ${device.sm} {
        width: 40%;
    };

    @media screen and ${device.tn} {
        width: 60%;
    };

`
export const IconWrap2 = styled.div`
    ${IconWrapStyle}
    display: ${({ active }) => (active ? 'flex' : 'none')};
    margin-left: 10px;
`
export const ForwradIcon = styled(IoIosArrowForward)`
    ${IconStyle}
`
export const SliderNavWrap = styled.div`
    position: absolute;
    left: 50%;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin-left: -40px;
    z-index: 3;
`
export const SliderSpan = styled.span`
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    border: 3px solid ${({ active }) => (active ? 'rgba(1, 219, 187, 0.5)' : 'gray')};
`
