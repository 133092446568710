import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { WContainer, WWrap, TypeWrap, TypeCard, ImageWrap, Image, WContext, WContextItem, CryptoFaucetPayWrap, CryptoWalletWrap } from './WithdrawElements';
import { NumberInputWrap, NumberInput } from '../SetUpPtc/SetUpPtcFilterElements';
import { TinyFormButton, FormButton } from '../ButtonElements';
import { cryptoLogoFaucetpayData, cryptoLogoWalletData } from '../../data/cryptoLogoData';
import { MsgWrap, MsgText } from '../MessageElements';
import Modal from './Modal';
import Countdown, { zeroPad } from 'react-countdown';
import { TimerWrap, TimerCode } from '../SettingContainer/ConfirmChangesElements';
import { ResetCodeWrap, NumberFormatCode } from '../Auth/ResetCodeElements';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const Withdraw = ({ info, userIcecoin }) => {
    const history = useHistory();
    const [type, setType] = useState(Object.freeze(''));
    const [typeIndex, setTypeIndex] = useState();
    const [autoStatus, setAutoStatus] = useState(false);
    const [apiError, setApiError] = useState('');
    const [errors, setErrors] = useState('');
    const [codeError, setCodeError] = useState('');
    const [limitationTime, setLimitationTime] = useState();
    const [cryptoName, setCryptoName] = useState(Object.freeze(''));
    const [crypto, setCrypto] = useState(Object.freeze(''));
    const [cryptoIndex, setCryptoIndex] = useState();
    const [value, setValue] = useState(Object.freeze(''));
    const [userAddress, setUserAddress] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [timer, setTimer] = useState();
    const [modalTimer, setModalTimer] = useState();
    const handleClick = (e, index) => {
        const typeTarget = e.target.getAttribute('name');
        if (typeTarget && typeTarget !== type) {
            setType(typeTarget);
            setTypeIndex(index);
            setValue(typeTarget === 'W' ? 10000 : 250);
            fetchLimitationCheck(typeTarget);
            setErrors('');
            setApiError('');
            setCrypto('');
            setCryptoName('');
            setCryptoIndex();
            setTimer();
            setIsSubmitting(false);
        };
    };
    const selectCrypto = (e, index) => {
        const cryptoTarget = e.target.getAttribute('value');
        const cryptoNameTarget = e.target.getAttribute('name');
        if (cryptoTarget && cryptoTarget !== crypto && cryptoNameTarget && cryptoNameTarget !== cryptoName) {
            setCrypto(cryptoTarget);
            setCryptoIndex(index);
            setCryptoName(cryptoNameTarget);
        };
        setIsSubmitting(false);
    };
    const handleChange = (e) => {
        let number = parseInt(e.target.value);
        if (!isNaN(number)) {
            setValue(parseInt(number));
        };
        setErrors('');
        setIsSubmitting(false);
    };
    const handleMaxClick = () => {
        type === 'F' && userIcecoin > 5000
            ? setValue(5000)
            : type === 'W' && userIcecoin > 200000
                ? setValue(200000)
                : setValue(userIcecoin);
    };
    const handleCashOutClick = () => {
        if (typeof (value) === 'string') {
            setErrors('You must enter the amount of your withdrawal first.');
        } else if (type === 'F' && value > 5000) {
            setErrors('The maximum withdrawal for Faucetpay wallet is 5000 icecoins.')
        } else if (type === 'F' && value < 250) {
            setErrors('The minimum withdrawal for Faucetpay wallet is 250 icecoins.')
        } else if (type === 'W' && value > 200000) {
            setErrors('The maximum withdrawal is 200000 icecoins.')
        } else if (type === 'W' && value < 10000) {
            setErrors('The minimum withdrawal is 10000 icecoins.')
        } else if (value > 0 && userIcecoin - value < 0) {
            setErrors('You do not have enough free icecoins.')
        } else {
            fetchUserAddress();
        };
    };
    const fetchLimitationCheck = async (typeTarget) => {
        try {
            const { data } = await axiosInstance.get(`financial/cash-out/check/${typeTarget}/`);
            setAutoStatus(data.status);
        } catch (error) {
            error.response && error.response.data.detail ? setLimitationTime(error.response.data.detail) : setLimitationTime(error.message);
        };
    };
    const fetchUserAddress = async () => {
        try {
            const { data } = await axiosInstance.post('financial/cash-out/user-address/', {
                'name': cryptoName,
                'type': type
            });
            userAddress.length === 0 && apiError.length === 0 && setUserAddress(data.address);
            fetchSendEmail();
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const fetchSendEmail = async () => {
        const { data } = await axiosInstance.get('financial/cash-out/send/email/');
        setTimer(Date.now() + data.timer * 1000);
    };
    const renderer = ({ minutes, seconds }) => {
        return <TimerWrap><TimerCode>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerCode></TimerWrap>;
    };
    const handleChangeCode = (e) => {
        let code = e.target.value.replace(/ /g, '').replace('_', '');
        setCodeError('');
        if (code.length === 6) {
            fetchCheckCode(code);
        };
    };
    const fetchCheckCode = async (code) => {
        try {
            await axiosInstance.post('financial/cash-out/check-code/', {
                code: code
            });
            setModalTimer(Date.now() + 5 * 60 * 1000);
            setIsSubmitting(true);
        } catch (error) {
            error.response && error.response.data.detail ? setCodeError(error.response.data.detail) : setCodeError(error.message);
        };
    };
    return (
        <WContainer>
            <WWrap>
                <TypeWrap>
                    {info.map((item, index) => (
                        <TypeCard key={index} primary={1} active={index === typeIndex ? 1 : 0}>
                            <ImageWrap primary={0}>
                                <Image src={item.src} alt={item.alt} title={item.title} />
                            </ImageWrap>
                            <WContext>
                                <WContextItem>
                                    <span>min withdraw:</span>
                                    <span>{item.min}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>max withdraw:</span>
                                    <span>{item.max}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>Fee:</span>
                                    <span>{item.fee}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>limitation:</span>
                                    <span>{item.limitation}</span>
                                </WContextItem>
                            </WContext>
                            <TinyFormButton type='button' name={item.type} primary={index === typeIndex ? 1 : 0} onClick={(e) => handleClick(e, index)}>
                                select
                            </TinyFormButton>
                        </TypeCard>
                    ))}
                </TypeWrap>
                {autoStatus && type === 'F' && !limitationTime &&
                    <CryptoFaucetPayWrap>
                        {cryptoLogoFaucetpayData.map((crypto, index) => (
                            <TypeCard key={index} style={{ width: '120px', height: '130px' }} primary={0} active={cryptoIndex === index ? 1 : 0}>
                                <ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
                                    <Image src={crypto.src} alt={crypto.alt} />
                                </ImageWrap>
                                <TinyFormButton primary={cryptoIndex === index ? 1 : 0} name={crypto.name} value={crypto.value} onClick={(e) => selectCrypto(e, index)}>
                                    {crypto.name2}
                                </TinyFormButton>
                            </TypeCard>
                        ))}
                    </CryptoFaucetPayWrap>
                }
                {autoStatus && type === 'W' && !limitationTime &&
                    <CryptoWalletWrap>
                        {cryptoLogoWalletData.map((crypto, index) => (
                            <TypeCard key={index} style={{ width: '120px', height: '130px' }} primary={0} active={cryptoIndex === index ? 1 : 0}>
                                <ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
                                    <Image src={crypto.src} alt={crypto.alt} />
                                </ImageWrap>
                                <TinyFormButton primary={cryptoIndex === index ? 1 : 0} name={crypto.name} value={crypto.value} onClick={(e) => selectCrypto(e, index)}>
                                    {crypto.name}
                                </TinyFormButton>
                            </TypeCard>
                        ))}
                    </CryptoWalletWrap>
                }
                {crypto && timer === undefined && !limitationTime &&
                    <NumberInputWrap>
                        <NumberInput
                            type='number'
                            value={value}
                            onChange={handleChange}
                            min={type === 'W' ? '10000' : '250'}
                            max={type === 'W' ? '200000' : '5000'}
                            step={100}
                        />
                        <TinyFormButton type='button' primary={0} big={0} onClick={handleMaxClick}>
                            max
                        </TinyFormButton>
                    </NumberInputWrap>
                }
                {apiError &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
                {errors &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {errors}
                        </MsgText>
                    </MsgWrap>
                }
                {codeError &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {codeError}
                        </MsgText>
                    </MsgWrap>
                }
                {limitationTime &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            You could withdraw again {moment(Date.now() + limitationTime * 1000).fromNow()}.
                        </MsgText>
                    </MsgWrap>
                }
                {crypto && timer === undefined && !apiError && !limitationTime && !errors &&
                    <FormButton
                        type='button'
                        style={{ width: '80%', maxWidth: '500px', marginBottom: '15px' }}
                        big={0}
                        primary={1}
                        onClick={() => handleCashOutClick()}
                    >
                        Cash out
                    </FormButton>
                }
                {timer !== undefined && !apiError && !limitationTime && !errors &&
                    <MsgWrap style={{ width: '80%' }} primary={1}>
                        <MsgText primary={1}>
                            <p>
                                An email contains a code has been sent to your email address. After enter the correct code in time , your updates are submitted. This way keep your account safe.
                            </p>
                        </MsgText>
                        <Countdown
                            date={timer}
                            renderer={renderer}
                            onComplete={() => {
                                setTimeout(() => history.go(0), 3000);
                                setTimer(0);
                            }}
                        />
                        {timer > 0 &&
                            <ResetCodeWrap>
                                <NumberFormatCode
                                    format={"#  #  #  #  #  #"}
                                    allowEmptyFormatting mask="_"
                                    onChange={handleChangeCode}
                                />
                            </ResetCodeWrap >
                        }
                        {timer === 0 &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    Your time is expired. Please try again.
                                </MsgText>
                            </MsgWrap>
                        }
                    </MsgWrap>
                }
            </WWrap>
            {
                !apiError && !errors && !codeError &&
                <Modal type={type} crypto={crypto} value={value} name={cryptoName} userAddress={userAddress} timer={modalTimer} setTimer={setModalTimer} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
            }
        </WContainer >
    )
}
export default Withdraw;
