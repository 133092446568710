import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation, useHistory } from 'react-router-dom';
import { SidebarContainer } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { ModalWrapper } from '../AdsControl/MoreInfoModalElements';
import { ContentWrap, TimePriceWrap, TimeWrap, PriceWrap, TimerDeposit, InstructionWrap, ValueAddressWrap, ItemContainer, ItemWrap } from './ModalElements';
import { RefButton } from '../Promotions/ReferralLinkTabElements';
import Countdown, { zeroPad } from 'react-countdown';
import { FormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { PtcInputWrap, PtcFormInput, PtcTitleIcon } from '../SetUpPtc/SetUpPtcFormElements.js';

const Modal = ({ type, crypto, address, value, timer, setTimer, isSubmitting, setIsSubmitting }) => {
    const location = useLocation();
    let history = useHistory();
    const [price, setPrice] = useState(Object.freeze());
    const [getPrice, setGetPrice] = useState(false);
    const [apiError, setApiError] = useState('');
    const [valueTX, setValueTX] = useState(Object.freeze(''));
    const [error, setError] = useState('');
    const [copied, setCopied] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [complete, setComplete] = useState('');
    const cryptoValue = crypto === 'usdttrc20' ? value / price / 10000 : (value / price / 10000 + 0.000001).toFixed(6);
    const fetchNowPaymentPrice = async () => {
        setGetPrice(true);
        try {
            const { data } = await axiosInstance.post('financial/cash-in/estimate/price/', {
                crypto: crypto
            });
            setPrice(parseFloat(data.price));
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const renderer = ({ minutes, seconds }) => {
        return <TimeWrap red={timer < Date.now() + 5 * 60 * 1000 ? 1 : 0}>
            <TimerDeposit red={timer < Date.now() + 5 * 60 * 1000 ? 1 : 0}>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerDeposit>
        </TimeWrap>;
    };
    const fetchAutoDeposit = async () => {
        try {
            await axiosInstance.post('financial/cash-in/', {
                'type': type,
                'price': price,
                'coin': location.pathname === '/deposit/pcoin' ? 'P' : 'A',
                'processor': crypto,
                'value_coin': value,
                'address': address
            });
            setComplete(true);
            setTimeout(() => history.push('/history-panel'), 3000);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const handleChange = (e) => {
        const targetValue = e.target.value;
        setValueTX(targetValue);
        setError('');
    };
    const fetchManualDeposit = async () => {
        try {
            await axiosInstance.post('financial/cash-in/', {
                'type': type,
                'price': price,
                'transaction_id': valueTX,
                'coin': location.pathname === '/deposit/pcoin' ? 'P' : 'A',
                'processor': crypto,
                'value_coin': value,
                'address': address,
                'status': 'P'
            });
            setComplete(true);
            setTimeout(() => history.push('/history-panel'), 3000);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const handleManualDeposit = () => {
        valueTX.length === 0 && setError('You must enter your transaction Id first.');
        valueTX.length > 0 && fetchManualDeposit();
    };
    const handleClick = () => {
        type === 'A' && fetchAutoDeposit();
        type === 'M' && handleManualDeposit();
    };
    const handleCopy = () => {
        if (navigator.clipboard && window.isSecureContext) {
            setCopied(true);
            setCopied2(false);
            return navigator.clipboard.writeText(cryptoValue);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = cryptoValue;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied(true);
                setCopied2(false);
            });
        };
    };
    const handleCopy2 = () => {
        if (navigator.clipboard && window.isSecureContext) {
            setCopied2(true);
            setCopied(false);
            return navigator.clipboard.writeText(address);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = address;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied2(true);
                setCopied(false);
            });
        };
    };
    useEffect(() => {
        if (isSubmitting && !getPrice) {
            fetchNowPaymentPrice();
        };
    });
    return (
        <SidebarContainer isOpen={isSubmitting ? 1 : 0}>
            <ModalWrapper>
                <CloseIconWrap onClick={() => { setIsSubmitting(false); setPrice(undefined); setApiError(''); }}>
                    <CloseIcon />
                </CloseIconWrap>
                {price !== undefined &&
                    <ContentWrap style={{ height: '90%' }}>
                        <div style={{ width: '100%' }}>
                            <TimePriceWrap>
                                <Countdown
                                    date={timer}
                                    renderer={renderer}
                                    onComplete={() => {
                                        setTimeout(() => history.go(0), 3000);
                                        setTimer(0);
                                    }}
                                />
                                <PriceWrap>
                                    <h2>1 {crypto === 'usdttrc20' ? 'usdt' : crypto} = {price.toFixed(3)} usdt</h2>
                                </PriceWrap>
                            </TimePriceWrap>
                            <InstructionWrap>
                                {type === 'A' ?
                                    <p>
                                        Please send exactly {cryptoValue} {crypto === 'usdttrc20' ? 'tether (USDT-TRC20)' : crypto.toUpperCase()} to the
                                        following address in less than 15 minutes and then press the following button.
                                        After the status of the payment is changed to "confirmed", your balance will be automatically charged.
                                    </p>
                                    : <p>
                                        Please send exactly {cryptoValue} {crypto === 'usdttrc20' ? 'tether (USDT-TRC20)' : crypto.toUpperCase()} to the
                                        following address in less than 15 minutes.
                                    </p>
                                }
                            </InstructionWrap>
                            <ValueAddressWrap>
                                <ItemContainer>
                                    <ItemWrap>
                                        <h3>{cryptoValue}</h3>
                                    </ItemWrap>
                                    <RefButton onClick={handleCopy}>
                                        {copied ? 'copied' : 'copy'}
                                    </RefButton>
                                </ItemContainer>
                                <ItemContainer>
                                    <ItemWrap>
                                        <h3>{address}</h3>
                                    </ItemWrap>
                                    <RefButton onClick={handleCopy2}>
                                        {copied2 ? 'copied' : 'copy'}
                                    </RefButton>
                                </ItemContainer>
                            </ValueAddressWrap>
                            {type === 'M' &&
                                <>
                                    <InstructionWrap style={{ marginBottom: '10px' }}>
                                        <p>
                                            You must enter your transaction Id here and then press the following button. The confirmation of your payment may take long between 1 hour till 2 days.
                                        </p>
                                    </InstructionWrap>
                                    <div style={{ margin: 'auto', width: '75%' }}>
                                        <PtcInputWrap>
                                            <PtcTitleIcon style={{ transform: 'translate(75%, 28%)' }} />
                                            <PtcFormInput
                                                style={{ marginBottom: '0' }}
                                                type='text'
                                                placeholder='Enter your transaction Id.'
                                                onChange={handleChange}
                                                value={valueTX}
                                            >
                                            </PtcFormInput>
                                        </PtcInputWrap>
                                    </div>
                                </>
                            }
                        </div>
                        {error &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    {error}
                                </MsgText>
                            </MsgWrap>
                        }
                        {apiError.length === 0 && !complete && timer > 0 &&
                            <FormButton
                                type='button'
                                style={{ width: '80%', maxWidth: '500px' }}
                                big={0}
                                primary={1}
                                onClick={handleClick}
                            >
                                Deposit
                            </FormButton>}
                        {apiError &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    {apiError}
                                </MsgText>
                            </MsgWrap>
                        }
                        {timer === 0 &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    Your time is expired. Please try again.
                                </MsgText>
                            </MsgWrap>
                        }
                        {complete && !apiError &&
                            <MsgWrap style={{ width: '80%' }} primary={1}>
                                <MsgText primary={1}>
                                    Your deposit was successfully submitted.
                                </MsgText>
                            </MsgWrap>
                        }
                    </ContentWrap>
                }
            </ModalWrapper>
        </SidebarContainer >
    )
}

export default Modal;