export const dailyBonusTabHeader = [
    'normal',
    'shortlinker',
    'offerist',
    'digital marketer'
];
export const spinTableHead = [
    '#',
    'username(id)',
    'reward',
    'time'
];
export const spinMembership = [
    {
	'src': require('../images/omega.png').default,
	'alt': 'omega',
	'button': '1 spin'
    },
    {
	'src': require('../images/beta.png').default,
	'alt': 'beta',
	'button': '3 spins'
    },
    {
	'src': require('../images/alpha.png').default,
	'alt': 'alpha',
	'button': '7 spins'
    }
];
