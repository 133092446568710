export const slUserTableHead = [
    'shortlink',
    'earn',
    'create'
];
export const slUserStatus = [
    {
	'level': 1,
	'up': 'level 1',
	'pc': 0,
	'next_round': 30000,
	'down': '0'
    },
    {
	'level': 2,
	'up': 'level 2',
	'pc': 30000,
	'next_round': 70000,
	'down': '+5%'
    },
    {
	'level': 3,
	'up': 'level 3',
	'pc': 70000,
	'next_round': 150000,
	'down': '+10%'
    },
    {
	'level': 4,
	'up': 'level 4',
	'pc': 150000,
	'next_round': 300000,
	'down': '+15%'
    },
    {
	'level': 5,
	'up': 'level 5',
	'pc': 300000,
	'down': '+20%'
    }
];
export const slUserStatusTableHead = [
    'TE required',
    'level',
    'ice bonus',
    'reward'
];
export const slUserStatusTableBody = [
    {
	'TE_required': 0,
	'level': 1,
	'ice_bonus': '0',
	'reward': '0'
    },
    {
	'TE_required': 30000,
	'level': 2,
	'ice_bonus': '+5%',
	'reward': '1500 PC'
    },
    {
	'TE_required': 70000,
	'level': 3,
	'ice_bonus': '+10%',
	'reward': '3000 PC'
    },
    {
	'TE_required': 150000,
	'level': 4,
	'ice_bonus': '+15%',
	'reward': '7000'
    },
    {
	'TE_required': 300000,
	'level': 5,
	'ice_bonus': '+20%',
	'reward': '15000'
    }
];
export const slFilters = [
    'all',
    'easy',
    'normal',
    'hard',
    'favorite',
    '+x%',
    'assistance'
];
export const slDeterminePriceForAssistance = [
    {
	'name': 'free',
	'price': 0
    },
    {
	'name': '100 PC',
	'price': 100
    },
    {
	'name': '150 PC',
	'price': 150
    },
    {
	'name': '200 PC',
	'price': 200
    },
];
