export const adsSlider = [
    {
        greenBg: true,
        description: 'Fill out a simple form to register on iceFaucet then sign in to your account.',
        img: require('../images/labtop-mm1.png').default,
        mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
        alt: 'register and login',
    },
    {
        greenBg: false,
        description: 'Deposit Adscoins to your account with cryptocurrencies.',
        img: 'https://icefaucet.com/media/help/blog/labtop-ads2.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-ads2.png',
        alt: 'deposit',
    },
    {
        greenBg: true,
        description: 'Also, you could use the "mining app" to earn free Adscoins.',
        img: 'https://icefaucet.com/media/help/blog/labtop-ads3.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-ads3.png', 
	alt: 'free Adscoin',
    },
    {
        greenBg: false,
        description: 'Select your ads package.',
        img: 'https://icefaucet.com/media/help/blog/labtop-ads4.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-ads4.png',
        alt: 'advertising package',
    },
    {
        greenBg: true,
        description: 'Set up your ads with many filters.',
        img: 'https://icefaucet.com/media/help/blog/labtop-ads5.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-ads5.png',
        alt: 'set up ads',
    },
    {
        greenBg: false,
        description: 'Manage and control your ads easily.',
        img: 'https://icefaucet.com/media/help/blog/labtop-ads6.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-ads6.png',
        alt: 'manage',
    },
    {
	greenBg: true,
	description: 'You could also access some useful information about your ads.',
	img: 'https://icefaucet.com/media/help/blog/labtop-ads7.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-ads7.png',
	alt: 'useful data',
    }
];
export const langAdsSlider = [
    {
        lang: 'es',
        info: [
            {
                greenBg: true,
                description: 'Complete un formulario simple para registrarse en iceFaucet y luego inicie sesión en su cuenta',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'registrarse e iniciar sesión',
            },
            {
                greenBg: false,
                description: 'Deposite Adscoins en su cuenta con criptomonedas.(es)',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads2.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads2.png',
		alt: 'depósito',
            },
            {
                greenBg: true,
                description: 'Además, puedes utilizar la "aplicación de minería" para ganar Adscoins gratis.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads3.png',
                alt: 'Adscoin gratis',
            },
            {
                greenBg: false,
                description: 'Seleccione su paquete de anuncios.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads4.png',
                alt: 'paquete publicitario',
            },
            {
                greenBg: true,
                description: 'Configure sus anuncios con muchos filtros.(es)',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads5.png',
                alt: 'configurar anuncios',
            },
            {
                greenBg: false,
                description: 'Administre y controle sus anuncios fácilmente.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads6.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads6.png',
                alt: 'administrar',
            },
	    {
		greenBg: true,
		description: 'También puede acceder a información útil sobre sus anuncios.',
		img: 'https://icefaucet.com/media/help/blog/labtop-ads7.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads7.png',
		alt: 'administrar',
	    }
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                greenBg: true,
                description: 'Заполните простую форму, чтобы зарегистрироваться на iceFaucet, затем войдите в свою учетную запись.',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'зарегистрироваться и войти',
            },
            {
                greenBg: false,
                description: 'Внесите Adscoins на свой счет с помощью криптовалют.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads2.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads2.png',
                alt: 'депозит',
            },
            {
                greenBg: true,
                description: 'Кроме того, вы можете использовать «приложение для майнинга», чтобы заработать бесплатные Adscoins.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads3.png',
                alt: 'бесплатно Adscoin',
            },
            {
                greenBg: false,
                description: 'Выберите свой рекламный пакет.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads4.png',
                alt: 'рекламный пакет',
            },
            {
                greenBg: true,
                description: 'Настройте свои объявления с множеством фильтров.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads5.png',
                alt: 'настроить рекламу',
            },
            {
                greenBg: false,
                description: 'Легко управляйте и контролируйте свои объявления.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads6.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads6.png',
                alt: 'управлять',
            },
	    {
		greenBg: true,
		description: 'Вы также можете получить доступ к некоторой полезной информации о ваших рекламу.',
		img: 'https://icefaucet.com/media/help/blog/labtop-ads7.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads7.png',
		alt: 'управлять',
	    }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                greenBg: true,
                description: 'Preencha um formulário simples para se registrar no iceFaucet e faça login na sua conta',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'cadastre-se e faça login',
            },
            {
                greenBg: false,
                description: 'Deposite Adscoins em sua conta com criptomoedas.(ru)',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads2.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads2.png',
		alt: 'depósito',
            },
            {
                greenBg: true,
                description: 'Also, you can use “mining app” to earn free Adscoins.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads3.png',
                alt: 'Adscoin grátis',
            },
            {
                greenBg: false,
                description: 'Selecione seu pacote de anúncios.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads4.png',
                alt: 'pacote de publicidade',
            },
            {
                greenBg: true,
                description: 'Configure seus anúncios com muitos filtros.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads5.png',
                alt: 'configurar anúncios',
            },
            {
                greenBg: false,
                description: 'Gerencie e controle seus anúncios facilmente.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads6.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads6.png',
                alt: 'gerenciar',
            },
	    {
		greenBg: true,
		description: 'Você também pode acessar algumas informações úteis sobre seus anúncios.',
		img: 'https://icefaucet.com/media/help/blog/labtop-ads7.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads7.png',
		alt: 'gerenciar',
	    }
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                greenBg: true,
                description: 'Remplissez un simple formulaire pour vous inscrire sur iceFaucet puis connectez-vous à votre compte',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: "s'inscrire et se connecter",
            },
            {
                greenBg: false,
                description: 'Déposez des Adscoins sur votre compte avec des crypto-monnaies.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads2.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads2.png',
                alt: 'dépôt',
            },
            {
                greenBg: true,
                description: "Vous pouvez également utiliser « l'application minière » pour gagner des Adscoins gratuits.",
                img: 'https://icefaucet.com/media/help/blog/labtop-ads3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads3.png',
                alt: 'Adscoin gratuit',
            },
            {
                greenBg: false,
                description: "Sélectionnez votre package d'annonces.",
                img: 'https://icefaucet.com/media/help/blog/labtop-ads4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads4.png',
                alt: 'forfait publicitaire',
            },
            {
                greenBg: true,
                description: 'Configurez vos annonces avec de nombreux filtres.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads5.png', 
		alt: 'mettre en place des annonces',
            },
            {
                greenBg: false,
                description: 'Gérez et contrôlez facilement vos annonces.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads6.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads6.png',
                alt: 'gérer',
            },
	    {
		greenBg: true,
		description: 'Vous pouvez également accéder à des informations utiles sur vos annonces.',
		img: 'https://icefaucet.com/media/help/blog/labtop-ads7.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads7.png',
		alt: 'gérer',
	    }
        ]
    },
    {
        lang: 'de',
        info: [
            {
                greenBg: true,
                description: 'Füllen Sie ein einfaches Formular aus, um sich bei iceFaucet zu registrieren, und melden Sie sich dann bei Ihrem Konto an.',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'registrieren und einloggen',
            },
            {
                greenBg: false,
                description: 'Zahlen Sie Adscoins mit Kryptowährungen auf Ihr Konto ein.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads2.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads2.png',
                alt: 'Kaution',
            },
            {
                greenBg: true,
                description: 'Außerdem können Sie die „Mining-App“ nutzen, um kostenlose Adscoins zu verdienen.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads3.png',
                alt: 'kostenlos Adscoin',
            },
            {
                greenBg: false,
                description: 'Wählen Sie Ihr Anzeigenpaket aus.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads4.png',
                alt: 'Werbepaket',
            },
            {
                greenBg: true,
                description: 'Richten Sie Ihre Anzeigen mit vielen Filtern ein.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads5.png',
                alt: 'Anzeigen einrichten',
            },
            {
                greenBg: false,
                description: 'Verwalten und steuern Sie Ihre Anzeigen ganz einfach.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads6.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads6.png',
                alt: 'verwalten',
            },
	    {
		greenBg: true,
		description: 'Sie können auch auf einige nützliche Informationen zu Ihren Anzeigen zugreifen.',
		img: 'https://icefaucet.com/media/help/blog/labtop-ads7.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads7.png',
		alt: 'verwalten',
	    }
        ]
    },
    {
        lang: 'it',
        info: [
            {
                greenBg: true,
                description: 'Compila un semplice modulo per registrarti su iceFaucet, quindi accedi al tuo account.',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'registrati e accedi',
            },
            {
                greenBg: false,
                description: 'Deposita Adscoin sul tuo conto con criptovalute.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads2.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads2.png',
                alt: 'depositare',
            },
            {
                greenBg: true,
                description: "Inoltre, potresti utilizzare l 'app di mining' per guadagnare Adscoin gratuiti.",
                img: 'https://icefaucet.com/media/help/blog/labtop-ads3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads3.png',
                alt: 'Adscoin gratuito',
            },
            {
                greenBg: false,
                description: 'Seleziona il tuo pacchetto di annunci.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads4.png', 
		alt: 'pacchetto pubblicitario',
            },
            {
                greenBg: true,
                description: 'Imposta i tuoi annunci con molti filtri.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads5.png',
                alt: 'impostare gli annunci',
            },
            {
                greenBg: false,
                description: 'Gestisci e controlla facilmente i tuoi annunci.',
                img: 'https://icefaucet.com/media/help/blog/labtop-ads6.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads6.png',
                alt: 'gestire gli annunci',
            },
	    {
		greenBg: true,
		description: 'Potresti anche accedere ad alcune informazioni utili sui tuoi annunci.',
		img: 'https://icefaucet.com/media/help/blog/labtop-ads7.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-ads7.png',
		alt: 'gestire gli annunci',
	    }
        ]
    }
];
