export const typeData = {
    subject: 'payments proof',
    card: [
        {
	    img: 'https://icefaucet.com/media/task/faucetpay.jpg',
            alt: 'FaucetPay',
            button: 'FaucetPay',
            name: 'F'
        },
        {
            img: require('../images/wallet.png').default,
            alt: 'Wallet',
            button: 'Wallet',
            name: 'W'
        }
    ]
};
export const headTable = [
    'number',
    'username(id)',
    'address',
    'processor',
    'value',
    'date'
];
export const smallHeadTable = [
    'address',
    'processor',
    'value'
];
