export const upgradeTableHead = [
    'details',
    'omega',
    'beta',
    'alpha'
];

export const upgradeTableRow = [
    {
        'detail': 'level requirement',
        'omega': '1',
        'beta': '1',
        'alpha': '2'
    },
    {
        'detail': 'cost of view ads',
        'omega': '%50',
        'beta': '%75',
        'alpha': '%100'
    },
    {
        'detail': 'cost of claim',
        'omega': '0',
        'beta': '+%200',
        'alpha': '+%500'
    },
    {
	'detail': 'daily bonus',
	'omega': '1',
	'beta': '3',
	'alpha': '7'
    },
    {
	'detail': 'surf mining adscoin reward',
	'omega': '5',
	'beta': '10',
	'alpha': '20'
    },
    {
	'detail': 'banner mining adscoin reward',
	'omega': '0.5',
	'beta': '1',
	'alpha': '2'
    },
    
    {
        'detail': 'rate of surf mining *',
        'omega': '50',
        'beta': '100',
        'alpha': '200'
    },
    {
        'detail': 'rate of banner mining **',
        'omega': '5',
        'beta': '10',
        'alpha': '20'
    },
    {
        'detail': 'maximum referrals',
        'omega': '1000',
        'beta': '5000',
        'alpha': '10000'
    },
    {
        'detail': 'monthly price',
        'omega': 'FREE',
        'beta': '$5',
        'alpha': '$10'
    },
    {
        'detail': '6 month price',
        'omega': 'FREE',
        'beta': '$25',
        'alpha': '$50'
    },
];
export const SelectMembership = [
    {
        'name': 0.50,
        'src': require('../images/omega.png').default,
        'alt': 'omega image'
    },
    {
        'name': 0.75,
        'src': require('../images/beta.png').default,
        'alt': 'beta image',
        'button': 'monthly',
        'button2': '6 months'
    },
    {
        'name': 1.00,
        'src': require('../images/alpha.png').default,
        'alt': 'alpha image',
        'button': 'monthly',
        'button2': '6 months'
    }
];
