import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { showMainbarAction } from './actions/showMainbarAction';
import styled from 'styled-components';
import GlobalStyles from './GlobalStyles';
import device from './constants/breakPoints';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import AuthNavbar from './components/AuthNavbar';
import Mainbar from './components/Mainbar';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import HomePage from './pages/Home';
import MakeMoneyPage from './pages/MakeMoney';
import AdvertisePage from './pages/Advertise';
import StatisticsPage from './pages/Statistics';
import AuthPage from './pages/AuthPage';
import Logout from './components/Auth/Logout';
import HelpPage from './pages/HelpPage';
import HelpBlogPage from './pages/HelpBlogPage';
import PaymentsPage from './pages/PaymentsPage';
import AboutUsPage from './pages/AboutUsPage';
import TOSPage from './pages/TOSPage';
import PrivacyPage from './pages/PrivacyPage';
import DashboardPage from './pages/Dashboard';
import UpgradePage from './pages/Upgrade';
import DepositPage from './pages/Deposit';
import WithdrawPage from './pages/Withdraw';
import FaucetPage from './pages/Faucet';
import PTCPage from './pages/PTC';
import SurfPage from './pages/Surf';
import VideoPage from './pages/Video';
import VideoPlayPage from './pages/VideoPlayPage';
import SLPage from './pages/SLPage';
import AssistantSLPage from './pages/AssistantSLPage';
import SLContestPage from './pages/SLContestPage';
import SLDonePage from './pages/SLDonePage';
import TaskPage from './pages/Task';
import LotteryPage from './pages/LotteryPage.js';
import OfferPage from './pages/OfferPage';
import OfferContestPage from './pages/OfferContestPage';
import OfferListCPX from './pages/OfferListCPX';
import OfferListTimeWall from './pages/OfferListTimeWall';
import OfferListNotik from './pages/OfferListNotik';
import OfferListMonlix from './pages/OfferListMonlix';
import OfferListBitlabs from './pages/OfferListBitlabs';
import OfferListBitcoTasks from './pages/OfferListBitcoTasks';
import OfferListEWall from './pages/OfferListEWall';
import OfferListOfferWall from './pages/OfferListOfferWall';
import OfferListExcentiv from './pages/OfferListExcentiv';
import OfferListAdtowall from './pages/OfferListAdtowall';
import SuccessFP from './pages/SuccessFP';
import FailFP from './pages/FailFP';
import DailyBonus from './pages/DailyBonusPage';
import SurfMiningPage from './pages/SurfMining';
import BannerMiningPage from './pages/BannerMining';
import SetUpAdsPage from './pages/SetUpAds';
import ControlAdsPage from './pages/ControlAds';
import PromotionMaterialsPage from './pages/PromotionMaterials';
import ManageReferralsPage from './pages/ManageReferrals';
import ActivitiesPage from './pages/Activities';
import ReferralsActivitiesPage from './pages/ReferralsActivities';
import HistoryPanelPage from './pages/HistoryPanel';
import SettingsPage from './pages/Settings';
import SetUpPtc from './components/SetUpPtc';
import ViewSetUpPtc from './components/SetUpWatchPtc/View';
import ViewSetUpSurf from './components/SetUpWatchSurf/View';
import PlaySetUpPtc from './components/SetUpWatchPtc/Play';
import PtcDetailPage from './pages/PtcDetailPage';
import PtcPlayPage from './pages/PtcPlayPage';
import SurfMiningPlayPage from './pages/SurfMiningPlayPage';
import SurfDetailPage from './pages/SurfDetailPage';
import SetUpBanner from './components/SetUpBanner';
import SetUpMining from './components/SetUpMining';
import SetUpSponsor from './components/SetUpSponsor';
import StackPage from './pages/StackPage';
import { useLocation } from 'react-router-dom';
import NotificationPage from './pages/NotificationPage';
import RedeemPage from './pages/RedeemPage';
import NotFoundPage from './pages/NotFoundPage';
import { MsgWrap, MsgText } from './components/MessageElements';

export const MotherContainer = styled.div`
  display: flex;
  position: relative;
  top: 60px;
  min-height: calc(100vh - 60px);
  background: ${({ dark }) => (dark ? '#111121' : '#f9f9f9')};
`

export const MainContainer = styled.main`
  position: relative;
  width: ${({ isShow }) => (isShow ? '67%' : '100%')};
  left: ${({ isShow }) => (isShow ? '33%' : '0')};
  transition: 200ms;
 
  @media screen and ${device.xl} {
    transition: 500ms;
    width: 75%;
    left: 25%;
  }

  @media screen and ${device.md} {
    transition: 500ms;
    width: 100%;
    left: 0;
  }
`

function App() {

  return (
    <>
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path='(/)' component={UnAuthorized} />
          <Route path='(/make-money)' component={UnAuthorized} />
          <Route path='(/advertise)' component={UnAuthorized} />
          <Route path='(/statistics)' component={UnAuthorized} />
          <Route path='(/ref)' component={Referral} />
          <Route path='(/lp)' component={LandingPage} />
          <Route path='(/auth)' component={Auth} />
          <Route path='(/logout)' component={Auth} />
          <Route path='(/info)' component={Info} />
          <Route path='(/about)' component={Info} />
          <Route path='(/help)' component={Info} />
          <Route path='(/payments)' component={Info} />
          <Route path='(/TOS)' component={Info} />
          <Route path='(/privacy)' component={Info} />
          <Route path='(/dashboard)' component={Authorized} />
          <Route path='(/upgrade)' component={Authorized} />
          <Route path='(/deposit)' component={Authorized} />
          <Route path='(/withdraw)' component={Authorized} />
          <Route path='(/setup-ads)' component={Authorized} />
          <Route path='(/control-ads)' component={Authorized} />
          <Route path='(/faucet)' component={Authorized} />
          <Route path='(/ptc)' component={Authorized} />
          <Route path='(/surf)' component={Authorized} />
          <Route path='(/video)' component={Authorized} />
	  <Route path='(/shortlink)' component={Authorized} />
	  <Route path='(/task)' component={Authorized} />
	  <Route path='(/lottery)' component={Authorized} />
	  <Route path='(/offers)' component={Authorized} />
	  <Route path='(/lucky-wheel)' component={Authorized} />
	  <Route path='(/faucetpay)' component={Authorized} />
          <Route path='(/surf-mining)' component={Authorized} />
          <Route path='(/banner-mining)' component={Authorized} />
          <Route path='(/promotion-materials)' component={Authorized} />
          <Route path='(/manage-referrals)' component={Authorized} />
          <Route path='(/stake)' component={Authorized} />
          <Route path='(/activities)' component={Authorized} />
          <Route path='(/referrals-activities)' component={Authorized} />
          <Route path='(/history-panel)' component={Authorized} />
          <Route path='(/settings)' component={Authorized} />
          <Route path='(/notifications)' component={Authorized} />
          <Route path='(/redeem)' component={Authorized} />
          <Route path='(/detail)' component={ViewDetailAds} />
          <Route path='(/play)' component={ViewPlayAds} />
	  <Route path='(/done)' component={ViewPlayAds} />
          <Route path='(/watch)' component={SetUpWatch} />
          <Route path='*' component={NotFoundPage} />
        </Switch>
      </Router>
    </>
  )
};

const UnAuthorized = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === '/' || location.pathname === '/make-money' || location.pathname === '/advertise' || location.pathname === '/statistics'
        ? <Navbar />
        : null
      }
      {location.pathname === '/' || location.pathname === '/make-money' || location.pathname === '/advertise' || location.pathname === '/statistics'
        ? <Sidebar />
        : null
      }
      <main>
        <Route path='/' component={HomePage} exact />
        <Route path='/make-money' component={MakeMoneyPage} exact />
        <Route path='/make-money/*' component={NotFoundPage} />
        <Route path='/advertise' component={AdvertisePage} exact />
        <Route path='/advertise/*' component={NotFoundPage} />
        <Route path='/statistics' component={StatisticsPage} exact />
        <Route path='/statistics/*' component={NotFoundPage} />
      </main>
      {location.pathname === '/' || location.pathname === '/make-money' || location.pathname === '/advertise' || location.pathname === '/statistics'
        ? <Footer />
        : null
      }
    </>
  )
};

const Auth = () => {
  const authorized = localStorage.getItem('access_token')
    ? true
    : false;
  const [refCode, setRefCode] = useState('');
  const [error, setError] = useState('');
  const fetchRefCode = async (code) => {
    try {
      await axios.get(`https://icefaucet.com/api/ref/check-code/${code}/`);
      setRefCode(code);
    } catch (error) {
      error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
    };
  };

  return (
    <>
      <Route path='/auth' exact>
        {authorized ? <Redirect to='/dashboard' /> : <AuthPage />}
      </Route >
      <Route path='/logout' exact>
        {authorized ? <Logout /> : <Redirect to='/auth' />}
      </Route>
      <Route path='/logout/*' component={NotFoundPage} />
      <Route path='/auth/ref/:code' exact render={(props) => {
        const code = props.match.params.code;
        if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
          fetchRefCode(code);
        };
        if (refCode.length !== 0) {
          return <AuthPage code={refCode} />
        };
      }} />
      <Route path='/auth/ref/:code/*' component={NotFoundPage} />
      {error &&
        <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
          <MsgText primary={0}>
            {error}
          </MsgText>
        </MsgWrap>
      }
    </>
  )
};

const Info = () => {
  const [refCode, setRefCode] = useState('');
  const [error, setError] = useState('');
  const fetchRefCode = async (code) => {
    try {
      await axios.get(`https://icefaucet.com/api/ref/check-code/${code}/`);
      setRefCode(code);
    } catch (error) {
      error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
    };
  };

  return (
    <>
      <Route path='/about' component={AboutUsPage} exact />
      <Route path='/about/*' component={NotFoundPage} />
      <Route path='/help' component={HelpPage} exact />
      <Route path='/help/blog/:blogCode' component={HelpBlogPage} exact />
      <Route path='/help/blog/:blogCode/*' component={NotFoundPage} />
      <Route path='/payments' exact>
        <Redirect to='/payments/1' />
      </Route>
      <Route path='/payments/:pageNumber' component={PaymentsPage} exact />
      <Route path='/payments/:pageNumber/*' component={NotFoundPage} />
      <Route path='/TOS' component={TOSPage} exact />
      <Route path='/TOS/*' component={NotFoundPage} />
      <Route path='/privacy' component={PrivacyPage} exact />
      <Route path='/privacy/*' component={NotFoundPage} />

      <Route path='/info/ref/:code/help' exact render={(props) => {
        const code = props.match.params.code;
        if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
          fetchRefCode(code);
        };
        if (refCode.length !== 0) {
          return <HelpPage code={refCode} />
        };
      }}
      />
      <Route path='/info/ref/:code/help/blog/:blogCode' exact render={(props) => {
        const code = props.match.params.code;
        if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
          fetchRefCode(code);
        };
        if (refCode.length !== 0) {
          return <HelpBlogPage code={refCode} />
        };
      }}
      />
      <Route path='/info/ref/:code/help/blog/:blogCode/*' component={NotFoundPage} />
      <Route path='/info/ref/:code/about' exact render={(props) => {
        const code = props.match.params.code;
        if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
          fetchRefCode(code);
        };
        if (refCode.length !== 0) {
          return <AboutUsPage code={refCode} />
        };
      }}
      />
      <Route path='/info/ref/:code/about/*' component={NotFoundPage} />
      <Route path='/info/ref/:code/TOS' exact render={(props) => {
        const code = props.match.params.code;
        if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
          fetchRefCode(code);
        };
        if (refCode.length !== 0) {
          return <TOSPage code={refCode} />
        };
      }}
      />
      <Route path='/info/ref/:code/TOS/*' component={NotFoundPage} />
      <Route path='/info/ref/:code/privacy' exact render={(props) => {
        const code = props.match.params.code;
        if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
          fetchRefCode(code);
        };
        if (refCode.length !== 0) {
          return <PrivacyPage code={refCode} />
        };
      }}
      />
      <Route path='/info/ref/:code/privacy/*' component={NotFoundPage} />
      <Route path='/info/ref/:code/payments/:pageNumber' exact render={(props) => {
        const code = props.match.params.code;
        if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
          fetchRefCode(code);
        };
        if (refCode.length !== 0) {
          return <PaymentsPage code={refCode} />
        };
      }}
      />
      <Route path='/info/ref/:code/payments/:pageNumber/*' component={NotFoundPage} />
      {error &&
        <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
          <MsgText primary={0}>
            {error}
          </MsgText>
        </MsgWrap>
      }
    </>
  )
};

const Authorized = () => {
  const dispatch = useDispatch();
  const isShow = useSelector(state => state.showMainbar);
  const authorized = localStorage.getItem('access_token') ? true : false;
  const dark = localStorage.getItem('lightness') ? true : false;
  const closeMainbar = () => {
    if (window.innerWidth < 1023) {
      dispatch(showMainbarAction({ prop: false }));
    } else {
      dispatch(showMainbarAction({ prop: true }));
    }
  };
  useEffect(() => {
    window.addEventListener('resize', closeMainbar);
    return () => {
      window.removeEventListener('resize', closeMainbar);
    };
  });

  return (
    <>
      <AuthNavbar />
      <MotherContainer dark={dark ? 1 : 0}>
        <Mainbar />
        <MainContainer isShow={isShow ? 1 : 0}>
          <Route path='/dashboard' exact>
            {authorized ? <DashboardPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/upgrade' exact>
            {authorized ? <UpgradePage /> : <Redirect to='/' />}
          </Route>
          <Route path='/deposit/pcoin' exact>
            {authorized ? <DepositPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/deposit/adscoin' exact>
            {authorized ? <DepositPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/withdraw' exact>
            {authorized ? <WithdrawPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/setup-ads' exact>
            {authorized ? <SetUpAdsPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/control-ads' exact>
            {authorized ? <ControlAdsPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/faucet' exact>
            {authorized ? <FaucetPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/ptc' exact>
            <Redirect to='/ptc/1' />
          </Route>
          <Route path='/ptc/:pageNumber' exact>
            {authorized ? <PTCPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/surf' exact>
            <Redirect to='/surf/1' />
          </Route>
          <Route path='/surf/:pageNumber' exact>
            {authorized ? <SurfPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/video' exact>
	    {authorized ? <VideoPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/shortlink' exact>
	    {authorized ? <SLPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/shortlink/contest' exact>
	    {authorized ? <SLContestPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/shortlink/assistant/access/:slCode/:code' exact>
	    {authorized ? <AssistantSLPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/task' exact>
	    {authorized ? <TaskPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/lottery' exact>
	    {authorized ? <LotteryPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers' exact>
	    {authorized ? <OfferPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/contest' exact>
	    {authorized ? <OfferContestPage /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/CPX' exact>
	    {authorized ? <OfferListCPX /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/timewall' exact>
	    {authorized ? <OfferListTimeWall /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/notik' exact>
	    {authorized ? <OfferListNotik /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/monlix' exact>
	    {authorized ? <OfferListMonlix /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/bitlabs/:types' exact>
	    {authorized ? <OfferListBitlabs /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/bitcotasks' exact>
	    {authorized ? <OfferListBitcoTasks /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/ewall' exact>
	    {authorized ? <OfferListEWall /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/offerwall' exact>
	    {authorized ? <OfferListOfferWall /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/excentiv' exact>
	    {authorized ? <OfferListExcentiv /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/offers/adtowall' exact>
	    {authorized ? <OfferListAdtowall /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/lucky-wheel' exact>
	    {authorized ? <DailyBonus /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/faucetpay/deposit/success' exact>
	    {authorized ? <SuccessFP /> : <Redirect to='/' />}
	  </Route>
	  <Route path='/faucetpay/deposit/fail' exact>
	    {authorized ? <FailFP /> : <Redirect to='/' />}
	  </Route>
          <Route path='/promotion-materials' exact>
            {authorized ? <PromotionMaterialsPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/manage-referrals/:pageNumber'>
            {authorized ? <ManageReferralsPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/stake' exact>
            {authorized ? <StackPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/surf-mining' exact>
            {authorized ? <SurfMiningPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/banner-mining' exact>
            {authorized ? <BannerMiningPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/activities' exact>
            {authorized ? <ActivitiesPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/referrals-activities' exact>
            {authorized ? <ReferralsActivitiesPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/history-panel' exact>
            {authorized ? <HistoryPanelPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/settings' exact>
            {authorized ? <SettingsPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/setup-ads/ptc' exact>
            {authorized ? <SetUpPtc /> : <Redirect to='/' />}
          </Route>
          <Route path='/setup-ads/surf' exact>
            {authorized ? <SetUpPtc /> : <Redirect to='/' />}
          </Route>
          <Route path='/setup-ads/video' exact>
            {authorized ? <SetUpPtc /> : <Redirect to='/' />}
          </Route>
          <Route path='/setup-ads/mining' exact>
            {authorized ? <SetUpMining /> : <Redirect to='/' />}
          </Route>
          <Route path='/setup-ads/banner' exact>
            {authorized ? <SetUpBanner /> : <Redirect to='/' />}
          </Route>
          <Route path='/setup-ads/sponsor-link' exact>
            {authorized ? <SetUpSponsor /> : <Redirect to='/' />}
          </Route>
          <Route path='/notifications' exact>
            {authorized ? <NotificationPage /> : <Redirect to='/' />}
          </Route>
          <Route path='/redeem' exact>
            {authorized ? <RedeemPage /> : <Redirect to='/' />}
          </Route>
        </MainContainer>
      </MotherContainer>

    </>
  )
};

const ViewDetailAds = () => {
  return (
    <>
      <Route path='/detail/ptc/:code' component={PtcDetailPage} />
      <Route path='/detail/surf/:code' component={SurfDetailPage} />
    </>
  )
};

const ViewPlayAds = () => {

  return (
    <>
      <Route path='/play/ptc/:code/:uidb64/:token' component={PtcPlayPage} />
      <Route path='/play/mining/:uidb64/:token' component={SurfMiningPlayPage} />
      <Route path='/done/sl/:code/:token' component={SLDonePage} />
      <Route path='/play/video/:code/:uidb64/:token' component={VideoPlayPage} />
    </>
  )
};

const SetUpWatch = () => {
  return (
    <>
      <Route path='/watch/ptc' exact component={ViewSetUpPtc} />
      <Route path='/watch/ptc/play' exact component={PlaySetUpPtc} />
      <Route path='/watch/surf' exact component={ViewSetUpSurf} />
    </>
  )
};

const Referral = () => {
  const [refCode, setRefCode] = useState('');
  const [error, setError] = useState('');
  const fetchRefCode = async (code) => {
    try {
      await axios.get(`https://icefaucet.com/api/ref/check-code/${code}/`);
      setRefCode(code);
    } catch (error) {
      error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
    };
  };

  return (
    <>
      {
        refCode.length !== 0 && !error &&
        <Navbar code={refCode} />
      }
      {
        refCode.length !== 0 && !error &&
        <Sidebar code={refCode} />
      }
      <main>
        <Route path='/ref/:code' exact render={(props) => {
          const code = props.match.params.code;
          if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
            fetchRefCode(code);
          };
          if (refCode.length !== 0) {
            return <HomePage />
          };
        }}
        />
        <Route path='/ref/:code/make-money' exact render={(props) => {
          const code = props.match.params.code;
          if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
            fetchRefCode(code);
          };
          if (refCode.length !== 0) {
            return <MakeMoneyPage />
          };
        }}
        />
        <Route path='/ref/:code/make-money/*' component={NotFoundPage} />
        <Route path='/ref/:code/advertise' exact render={(props) => {
          const code = props.match.params.code;
          if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
            fetchRefCode(code);
          };
          if (refCode.length !== 0) {
            return <AdvertisePage />
          };
        }}
        />
        <Route path='/ref/:code/advertise/*' component={NotFoundPage} />
        <Route path='/ref/:code/statistics' exact render={(props) => {
          const code = props.match.params.code;
          if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
            fetchRefCode(code);
          };
          if (refCode.length !== 0) {
            return <StatisticsPage />
          };
        }}
        />
        <Route path='/ref/:code/statistics/*' component={NotFoundPage} />
      </main>
      {
        refCode.length !== 0 && !error &&
        <Footer code={refCode} />
      }
      {error &&
        <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
          <MsgText primary={0}>
            {error}
          </MsgText>
        </MsgWrap>
      }
    </>
  )
};

const LandingPage = () => {
  const [refCode, setRefCode] = useState('');
  const [error, setError] = useState('');
  const fetchRefCode = async (code) => {
    try {
      await axios.get(`https://icefaucet.com/api/ref/check-code/${code}/`);
      setRefCode(code);
    } catch (error) {
      error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
    };
  };

  return (
    <>
      <main>
        <Route path='/lp/1/:code' render={(props) => {
          const code = props.match.params.code;
          if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
            fetchRefCode(code);
          };
          if (refCode.length !== 0) {
            return <h1>landing page number one</h1>
          };
        }}
        />
        <Route path='/lp/2/:code' render={(props) => {
          const code = props.match.params.code;
          if (refCode.length === 0 && (/^[A-Z0-9]+$/i.test(code)) && code === code.toUpperCase() && code.length <= 15) {
            fetchRefCode(code);
          };
          if (refCode.length !== 0) {
            return <h1>landing page number two</h1>
          };
        }}
        />
      </main>
      {error &&
        <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
          <MsgText primary={0}>
            {error}
          </MsgText>
        </MsgWrap>
      }
    </>
  )
};

export default App;
