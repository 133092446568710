export const makeMoneySlider = [
    {
        greenBg: true,
        description: 'Fill out a simple form to register on iceFaucet then sign in to your account.',
        img: require('../images/labtop-mm1.png').default,
        mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
        alt: 'register and login',
    },
    {
        greenBg: false,
        description: 'There are several ways to earn such as "DAILY BONUS", "FAUCET", "OFFER", "VIEW ADS", "TASK", "LOTTERY", "AFFILIATE", and "GIFT CODE". All these ways make Primecoin.',
        img: 'https://icefaucet.com/media/help/blog/labtop-mm2_eL1Q9CW.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-mm2.png',
        alt: 'make money ways',
    },
    {
        greenBg: true,
        description: 'To exchange your Primecoins for Icecoins, you must use "MINING ICECOIN".',
        img: 'https://icefaucet.com/media/help/blog/labtop-mm3.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-mm3.png',
        alt: 'mining',
    },
    {
        greenBg: false,
        description: 'You could hold your Icecoins to level up your account or withdraw them to your cryptocurrency wallet.',
        img: 'https://icefaucet.com/media/help/blog/labtop-mm4.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-mm4.png',
        alt: 'withdraw',
    },
    {
        greenBg: true,
        description: 'Also, you could rent refrigerators to keep your Icecoins there and claim your profit whenever you want.',
        img: 'https://icefaucet.com/media/help/blog/labtop-mm5.png',
	mobile: 'https://icefaucet.com/media/help/blog/mobile-mm5.png',
        alt: 'stack',
    }
];
export const langMakeMoneySlider = [
    {
        lang: 'es',
        info: [
            {
                greenBg: true,
                description: 'Complete un formulario simple para registrarse en iceFaucet y luego inicie sesión en su cuenta',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'registrarse e iniciar sesión',
            },
            {
                greenBg: false,
                description: 'Hay varias formas de ganar, como "BONO DIARIO", "FAUCET", "OFERTA", "VER ANUNCIOS", "TAREA", "LOTERÍA", "AFILIADO" y "CÓDIGO REGALO". Todas estas formas crean Primecoin.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm2_eL1Q9CW.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm2.png',
                alt: 'formas de ganar dinero',
            },
            {
                greenBg: true,
                description: 'Para cambiar tus Primecoins por Icecoins, debes usar la minería.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm3.png',
                alt: 'minería',
            },
            {
                greenBg: false,
                description: 'Puede mantener sus Icecoins para subir de nivel su cuenta o retirarlos a su billetera de criptomonedas.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm4.png',
                alt: 'retirar',
            },
            {
                greenBg: true,
                description: 'Además, puedes alquilar refrigeradores para guardar tus Icecoins allí y reclamar tus ganancias cuando quieras.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm5.png',
                alt: 'ganancia',
            }
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                greenBg: true,
                description: 'Заполните простую форму, чтобы зарегистрироваться на iceFaucet, затем войдите в свою учетную запись.',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'зарегистрироваться и войти',
            },
            {
                greenBg: false,
                description: 'Есть несколько способов заработать, например, «ежедневный бонус», «сборщик», «предложение», «просмотр рекламы», «задача», «лотерея», «филиал» и «подарочный код». Все эти способы делают Primecoin.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm2_eL1Q9CW.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm2.png',
                alt: 'зарабатывать деньги способами',
            },
            {
                greenBg: true,
                description: 'Чтобы обменять ваши Primecoins на Icecoins, вы должны использовать майнинг.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm3.png',
                alt: 'добыча',
            },
            {
                greenBg: false,
                description: 'Вы можете оставить свои Icecoins для повышения уровня своей учетной записи или вывести их на свой криптовалютный кошелек.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm4.png',
                alt: 'отзывать',
            },
            {
                greenBg: true,
                description: 'Кроме того, вы можете арендовать холодильники, чтобы хранить там свои Icecoins и получать прибыль в любое время.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm5.png',
                alt: 'выгода',
            }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                greenBg: true,
                description: 'Preencha um formulário simples para se registrar no iceFaucet e faça login na sua conta',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'cadastre-se e faça login',
            },
            {
                greenBg: false,
                description: 'Existem diversas formas de ganhar como “BÔNUS DIÁRIO”, “FAUCET”, “OFERTA”, “VER ANÚNCIOS”, “TAREFA”, “LOTERIA”, “AFILIAR” e “CÓDIGO DE PRESENTE”. Todas essas formas formam Primecoin.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm2_eL1Q9CW.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm2.png',
                alt: 'ganhar dinheiro maneiras',
            },
            {
                greenBg: true,
                description: 'Para trocar suas Primecoins por Icecoins, você deve usar a mineração.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm3.png',
                alt: 'mineração',
            },
            {
                greenBg: false,
                description: 'Você pode manter seus Icecoins para aumentar o nível de sua conta ou retirá-los para sua carteira de criptomoedas.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm4.png',
                alt: 'retirar',
            },
            {
                greenBg: true,
                description: 'Além disso, você pode alugar geladeiras para manter seus Icecoins lá e reivindicar seu lucro sempre que quiser.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm5.png',
                alt: 'lucro',
            }
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                greenBg: true,
                description: 'Remplissez un simple formulaire pour vous inscrire sur iceFaucet puis connectez-vous à votre compte',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: "s'inscrire et se connecter",
            },
            {
                greenBg: false,
                description: "Il existe plusieurs façons de gagner, telles que « BONUS QUOTIDIEN », « FAUCET », « OFFRE », « REGARDER DES ANNONCES », « TÂCHE », « LOTERIE », « AFFILIER » et « CODE CADEAU ». Toutes ces façons font Primecoin.",
                img: 'https://icefaucet.com/media/help/blog/labtop-mm2_eL1Q9CW.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm2.png',
                alt: "gagner de l'argent façons",
            },
            {
                greenBg: true,
                description: 'Pour échanger vos Primecoins contre des Icecoins, vous devez utiliser le minage.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm3.png',
                alt: 'exploitation minière',
            },
            {
                greenBg: false,
                description: 'Vous pouvez conserver vos Icecoins pour améliorer votre compte ou les retirer dans votre portefeuille de crypto-monnaie.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm4.png',
                alt: 'retirer',
            },
            {
                greenBg: true,
                description: 'De plus, vous pouvez louer des réfrigérateurs pour y conserver vos Icecoins et réclamer vos bénéfices quand vous le souhaitez.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm5.png',
                alt: 'profit',
            }
        ]
    },
    {
        lang: 'de',
        info: [
            {
                greenBg: true,
                description: 'Füllen Sie ein einfaches Formular aus, um sich bei iceFaucet zu registrieren, und melden Sie sich dann bei Ihrem Konto an.',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'registrieren und einloggen',
            },
            {
                greenBg: false,
                description: 'Es gibt verschiedene Möglichkeiten, Geld zu verdienen, z. B. „TÄGLICHER BONUS“, „FAUCET“, „ANGEBOT“, „ANZEIGEN ANZEIGEN“, „AUFGABE“, „LOTTERIE“, „AFFILIATE“ und „GESCHENKCODE“. All diese Möglichkeiten machen Primecoin aus.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm2_eL1Q9CW.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm2.png',
                alt: 'Geld verdienen Wege',
            },
            {
                greenBg: true,
                description: 'Um Ihre Primecoins gegen Icecoins einzutauschen, müssen Sie Mining verwenden.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm3.png',
                alt: 'Bergbau',
            },
            {
                greenBg: false,
                description: 'Sie können Ihre Icecoins halten, um Ihr Konto zu verbessern, oder sie in Ihre Kryptowährungs-Brieftasche abheben.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm4.png',
                alt: 'zurückziehen',
            },
            {
                greenBg: true,
                description: 'Sie können auch Kühlschränke mieten, um Ihre Icecoins dort aufzubewahren und Ihren Gewinn zu erhalten, wann immer Sie möchten.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm5.png',
                alt: 'profitieren',
            }
        ]
    },
    {
        lang: 'it',
        info: [
            {
                greenBg: true,
                description: 'Compila un semplice modulo per registrarti su iceFaucet, quindi accedi al tuo account.',
                img: require('../images/labtop-mm1.png').default,
                mobile: 'https://icefaucet.com/media/help/blog/mobile-mm1.png',
                alt: 'registrati e accedi',
            },
            {
                greenBg: false,
                description: 'Esistono diversi modi per guadagnare come "BONUS GIORNALIERO", "FAUCET", "OFFERTA", "VISUALIZZA ANNUNCI", "COMPITO", "LOTTERIA", "AFFILIATO" e "CODICE REGALO". Tutti questi modi creano Primecoin.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm2_eL1Q9CW.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm2.png',
                alt: 'fare soldi modi',
            },
            {
                greenBg: true,
                description: 'Per scambiare i tuoi Primecoin con Icecoin, devi usare il mining.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm3.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm3.png',
                alt: 'estrazione',
            },
            {
                greenBg: false,
                description: 'Puoi tenere i tuoi Icecoin per far salire di livello il tuo account o ritirarli nel tuo portafoglio di criptovalute.',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm4.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm4.png',
                alt: 'ritirare',
            },
            {
                greenBg: true,
                description: 'Inoltre, puoi noleggiare frigoriferi per conservare lì i tuoi Icecoin e richiedere il tuo profitto quando vuoi',
                img: 'https://icefaucet.com/media/help/blog/labtop-mm5.png',
		mobile: 'https://icefaucet.com/media/help/blog/mobile-mm5.png',
                alt: 'profitto',
            }
        ]
    }
];
