import { FaBitcoin } from 'react-icons/fa';

export const howEarnData = [
    {
	title: 'Daily Bonus',
	icon: <FaBitcoin />,
	desc1: 'Complete the requirements to get your lucky wheel as a daily bonus',
	desc2: 'Spin the lucky wheel whenever you like',
	desc3: 'Boost the number of lucky wheels by upgrading your account',
	desc4: '',
    },
    {
	title: 'faucet',
	icon: <FaBitcoin />,
	desc1: 'Claim every hour',
	desc2: 'On icedays claim every 10 minutes',
	desc3: 'Claim up to 30X',
	desc4: 'Unlimited',
    },
    {
	title: 'offer',
	icon: <FaBitcoin />,
	desc1: 'Take a survey or do a task to earn massively',
	desc2: 'High conservation rate in the market',
	desc3: 'Keep your offer level 5 to earn 10% more',
	desc4: '',
    },
    {
	title: 'view ads',
	icon: <FaBitcoin />,
	desc1: 'Earn by watching the ads',
	desc2: 'Boost your earnings to 2X by upgrading your account',
	desc3: 'Increase 1% Faucet earnings by watching every view ads',
	desc4: '',
    },
    {
	title: 'shortlink',
	icon: <FaBitcoin />,
	desc1: 'Earn by completing the shortlink',
	desc2: "Earn based on your country's rate",
	desc3: 'Increase 2% faucet earnings by completing every shortlink',
	desc4: 'Double your earnings by upgrading your account to the Alpha membership',
    },
    {
	title: 'task',
	icon: <FaBitcoin />,
	desc1: 'Earn by doing some iceFaucet tasks',
	desc2: 'Do tasks professionally to double your earning',
	desc3: 'Refer the task to someone who can do it and earn 5% of the task earning',
	desc4: 'Anti-waste time system',
    },
    {
	title: 'lottery',
	icon: <FaBitcoin />,
	desc1: 'Buy tickets and try your chance in the monthly lottery',
	desc2: 'Every lottery is started with an initial reward',
	desc3: 'All tickets have the same chance of winning',
	desc4: 'Every user can buy a maximum of 20 tickets',
    },
    {
	title: 'affiliate',
	icon: <FaBitcoin />,
	desc1: 'You earn whenever your referrals earn or purchase ads',
	desc2: 'Useful promotional materials',
	desc3: 'Manage your referrals easily',
	desc4: 'lifetime earnings',
    },
    {
	title: 'gift code',
	icon: <FaBitcoin />,
	desc1: 'Enter your gift code to receive your reward instantly',
	desc2: "Follow iceFaucet's social media or be an active user to get the codes",
	desc3: '',
	desc4: '',
    },
    {
	title: 'stake',
	icon: <FaBitcoin />,
	desc1: 'Get profit by renting refrigerators to keep your Icecoins there',
	desc2: 'Claim your profit whenever you like',
	desc3: 'Enjoy watching how your profit increase',
	desc4: 'Increase annual interest to 20%',
    },
    {
	title: 'offer contest',
	icon: <FaBitcoin />,
	desc1: 'Weekly smart contest',
	desc2: 'infinity rewards',
	desc3: 'Completing more offers makes a bigger prize and more winners',
	desc4: '',
    },
    {
	title: 'shortlink contest',
	icon: <FaBitcoin />,
	desc1: 'Weekly smart contest',
	desc2: 'infinity rewards',
	desc3: 'Completing more shortlinks makes a bigger prize and more winners',
	desc4: '',
    },
    {
	title: 'multi-level system',
	icon: <FaBitcoin />,
	desc1: 'Level up your stake status to get more profit',
	desc2: 'Level up your offer status to get 10% more earn',
	desc3: 'Level up your shortlink status to get 20% more earn and receive the rewards',
	desc4: 'Become an assistant to help other users and earn more',
    }
];
export const langHowEarnData = [
    {
        lang: 'es',
        info: [
	    {
		title: 'Bonus diario',
		icon: <FaBitcoin />,
		desc1: 'Completa los requisitos para conseguir tu rueda de la suerte como bonificación diaria',
		desc2: 'Gira la rueda de la suerte cuando quieras',
		desc3: 'Aumente la cantidad de ruedas de la suerte actualizando su cuenta',
		desc4: '',
	    },
	    {
		title: 'faucet',
		icon: <FaBitcoin />,
		desc1: 'Gana cada hora',
		desc2: 'En los días de hielo reclama cada 10 minutos',
		desc3: 'Gana hasta 30X',
		desc4: 'Sin limites',
	    },
	    {
		title: 'oferta',
		icon: <FaBitcoin />,
		desc1: 'Responda una encuesta o realice una tarea para ganar mucho',
		desc2: 'Alto índice de conservación en el mercado.',
		desc3: 'Mantén tu oferta en el nivel 5 para ganar un 10% más',
		desc4: '',
	    },
	    {
		title: 'ver anuncios',
		icon: <FaBitcoin />,
		desc1: 'Gana viendo anuncios',
		desc2: 'Aumente sus ganancias a 2X al actualizando su cuenta',
		desc3: 'Aumente las ganancias de Faucet en un 1% completando cada vista de anuncios',
		desc4: '',
	    },
	    {
		title: 'enlace corto',
		icon: <FaBitcoin />,
		desc1: 'Gane completando el enlace corto',
		desc2: 'Gane según la tarifa de su país',
		desc3: 'Aumente las ganancias de Faucet en un 2% completando cada enlace corto',
		desc4: 'Duplique sus ganancias actualizando su cuenta a membresía Alpha',
	    },
	    {
		title: 'tarea',
		icon: <FaBitcoin />,
		desc1: 'Gana haciendo algunas tareas',
		desc2: 'Haz tareas profesionalmente para duplicar tus ganancias',
		desc3: 'Refiera la tarea a alguien que pueda hacerla y gane el 5% de la tarea ganada',
		desc4: 'Sistema anti-pérdida de tiempo',
	    },
	    {
		title: 'lotería',
		icon: <FaBitcoin />,
		desc1: 'Compra boletos y prueba tu oportunidad en la lotería mensual.',
		desc2: 'Cada lotería comienza con una recompensa inicial.',
		desc3: 'Todos los boletos tienen las mismas posibilidades de ganar.',
		desc4: 'Cada usuario puede comprar un máximo de 20 entradas.',
	    },
	    {
		title: 'afiliado',
		icon: <FaBitcoin />,
		desc1: 'Usted gana cada vez que sus referencias ganan o compran anuncios',
		desc2: 'Materiales promocionales útiles',
		desc3: 'Administre sus referencias fácilmente',
		desc4: 'ganancias de por vida',
	    },
	    {
		title: 'código regalo',
		icon: <FaBitcoin />,
		desc1: 'Ingrese su código de regalo para recibir su recompensa al instante.',
		desc2: "Siga las redes sociales de iceFaucet o sea un usuario activo para obtener los códigos",
		desc3: '',
		desc4: '',
	    },
	    {
		title: 'apostar',
		icon: <FaBitcoin />,
		desc1: 'Obtenga ganancias alquilando refrigeradores para mantener sus Icecoins allí',
		desc2: 'Reclama tus ganancias cuando quieras',
		desc3: 'Disfruta viendo como aumentan tus ganancias',
		desc4: 'Aumentar el interés anual al 20%',
	     },
	     {
		title: 'concurso oferta',
		icon: <FaBitcoin />,
		desc1: 'Concurso inteligente semanal',
		desc2: 'recompensas infinitas',
		desc3: 'Completar más ofertas hace un premio más grande y más ganadoras.',
		desc4: '',
	     },
	     {
		title: 'concurso enlace corto',
		icon: <FaBitcoin />,
		desc1: 'Concurso inteligente semanal',
		desc2: 'recompensas infinitas',
		desc3: 'Completar más enlaces hace un premio más grande y más ganadoras.',
		desc4: '',
	     },
	     {
		title: 'sistema multinivel',
		icon: <FaBitcoin />,
		desc1: 'Sube de nivel el estado de tu apuesta para obtener más ganancias',
		desc2: 'Sube de nivel el estado de tu oferta para obtener un 10% más de ganancias',
		desc3: 'Sube de nivel tu estado de enlace corto para obtener un 20% más de ganancias y recibir recompensas',
		desc4: 'Conviértete en asistente para ayudar a otros usuarios y ganar más',
	     }
        ]
    },
    {
        lang: 'ru',
        info: [
	    {
		title: 'ежедневный бонус',
		icon: <FaBitcoin />,
		desc1: 'Выполните требования, чтобы получить колесо удачи в качестве ежедневного бонуса',
		desc2: 'Крутите колесо удачи, когда захотите',
		desc3: 'Увеличьте количество счастливых колес, обновив свою учетную запись',
		desc4: '',
	    },
	    {
		title: 'сборщик',
		icon: <FaBitcoin />,
		desc1: 'Зарабатывай каждый час',
		desc2: 'В ледовые дни заработок каждые 10 минут',
		desc3: 'Зарабатывайте до 30 раз',
		desc4: 'Неограниченный',
	    },
	    {
		title: 'предложение',
		icon: <FaBitcoin />,
		desc1: 'Пройдите опрос или выполните задание, чтобы заработать много',
		desc2: 'Высокая степень консервации на рынке',
		desc3: 'Сохраняйте уровень вашего предложения 5, чтобы зарабатывать на 10% больше.',
		desc4: '',
	    },
	    {
		title: 'просмотр рекламы',
		icon: <FaBitcoin />,
		desc1: 'Заработок на просмотре рекламы',
		desc2: 'Увеличьте свой заработок в 2 раза, обновив свой аккаунт',
		desc3: 'Увеличивайте доход сборщика на 1%, просматривая каждый просмотр рекламы',
		desc4: '',
	    },
	    {
		title: 'короткая ссылка',
		icon: <FaBitcoin />,
		desc1: 'Заработайте, заполнив короткую ссылку',
		desc2: 'Зарабатывайте по курсу вашей страны',
		desc3: 'Увеличьте доход сборщика на 2%, заполнив каждую короткая ссылка',
		desc4: 'Удвойте свой заработок, повысив уровень своей учетной записи до уровня Альфа.',
	    },
	    {
		title: 'задача',
		icon: <FaBitcoin />,
		desc1: 'Зарабатывайте, выполняя некоторые задания',
		desc2: 'Выполняйте задания профессионально, чтобы удвоить свой заработок',
		desc3: 'Порекомендуйте задачу тому, кто может ее выполнить, и заработайте 5% от суммы, заработанной за задачу',
		desc4: 'Система защиты от потери времени',
	    },
	    {
		title: 'лотерея',
		icon: <FaBitcoin />,
		desc1: 'Покупайте билеты и испытайте свой шанс в ежемесячной лотерее',
		desc2: 'Каждая лотерея начинается с первоначального вознаграждения',
		desc3: 'Все билеты имеют одинаковые шансы на выигрыш',
		desc4: 'Каждый пользователь может купить максимум 20 билетов.',
	    },
	    {
		title: 'филиал',
		icon: <FaBitcoin />,
		desc1: 'Вы зарабатываете всякий раз, когда ваши рефералы зарабатывают или покупают рекламу',
		desc2: 'Полезные рекламные материалы',
		desc3: 'Легко управляйте своими рефералами',
		desc4: 'пожизненный заработок',
	    },
	    {
		title: 'подарочный код',
		icon: <FaBitcoin />,
		desc1: 'Введите подарочный код, чтобы мгновенно получить награду.',
		desc2: 'Подпишитесь на социальные сети IceFaucet или будьте активным пользователем, чтобы получить коды.',
		desc3: '',
		desc4: '',
	    },
	    {
		title: 'СТАВКА',
		icon: <FaBitcoin />,
		desc1: 'Получайте прибыль, сдавая в аренду холодильники, чтобы хранить там свои Icecoins',
		desc2: 'Получите свою прибыль, когда захотите',
		desc3: 'Наслаждайтесь просмотром, как увеличивается ваша прибыль',
		desc4: 'Увеличить годовую процентную ставку до 20%',
	    },
	    {
		title: 'предложение конкурс',
		icon: <FaBitcoin />,
		desc1: 'Еженедельный умный конкурс',
		desc2: 'бесконечные награды',
		desc3: 'Выполнив больше предложений, вы получите больший приз и больше победителей.',
		desc4: '',
	    },
	    {
		title: 'короткая ссылка конкурс',
		icon: <FaBitcoin />,
		desc1: 'Еженедельный умный конкурс',
		desc2: 'бесконечные награды',
		desc3: 'Если вы заполните больше коротких ссылок, вы получите больший приз и больше победителей.',
		desc4: '',
	    },
	    {
		title: 'многоуровневая система',
		icon: <FaBitcoin />,
		desc1: 'Повышайте статус своей ставки, чтобы получать больше прибыли',
		desc2: 'Повышайте статус своего предложения, чтобы получать на 10% больше дохода',
		desc3: 'Повышайте свой статус короткой ссылки, чтобы получать на 20% больше заработка и награды.',
		desc4: 'Станьте помощником, чтобы помогать другим пользователям и зарабатывать больше',
	    }
        ]
    },
    {
        lang: 'pt',
	info: [
	    {
		title: 'BÔNUS DIÁRIO',
		icon: <FaBitcoin />,
		desc1: 'Complete os requisitos para receber sua roda da sorte como bônus diário',
		desc2: 'Gire a roda da sorte sempre que quiser',
		desc3: 'Aumente o número de rodas da sorte atualizando sua conta',
		desc4: '',
	    },
	    {
		title: 'faucet',
		icon: <FaBitcoin />,
		desc1: 'Ganhe a cada hora',
		desc2: 'Em dias de gelo, reivindique a cada 10 minutos',
		desc3: 'Ganhe até 30X',
		desc4: 'Ilimitada',
	    },
	    {
		title: 'oferta',
		icon: <FaBitcoin />,
		desc1: 'Responda a uma pesquisa ou faça uma tarefa para ganhar muito',
		desc2: 'Alta taxa de conservação no mercado',
		desc3: 'Mantenha sua oferta no nível 5 para ganhar 10% a mais',
		desc4: '',
	    },
	    {
		title: 'visualizar anúncios',
		icon: <FaBitcoin />,
		desc1: 'Ganhe assistindo anúncios',
		desc2: 'Aumente seus ganhos para 2X atualizando sua conta',
		desc3: 'Aumente os ganhos do Faucet em 1% assistindo a todos os anúncios de exibição',
		desc4: '',
	    },
	    {
		title: 'ligação curta',
		icon: <FaBitcoin />,
		desc1: 'Ganhe completando o link curto',
		desc2: 'Ganhe com base na taxa do seu país',
		desc3: 'Aumente os ganhos do Faucet em 2% ao concluir todos os links curtos',
		desc4: 'Dobre seus ganhos atualizando sua conta para a associação Alpha',	
	    },
	    {
		title: 'tarefa',
		icon: <FaBitcoin />,
		desc1: 'Ganhe fazendo algumas tarefas',
		desc2: 'Faça tarefas profissionalmente para dobrar seus ganhos',
		desc3: 'Indique a tarefa para alguém que possa fazê-la e ganhe 5% do ganho da tarefa',
		desc4: 'Sistema de tempo anti-desperdício',
	    },
	    {
		title: 'loteria',
		icon: <FaBitcoin />,
		desc1: 'Compre ingressos e tente sua chance na loteria mensal',
		desc2: 'Cada loteria começa com uma recompensa inicial',
		desc3: 'Todos os ingressos têm a mesma chance de ganhar',
		desc4: 'Cada usuário pode comprar no máximo 20 ingressos',
	    },
	    {
		title: 'afiliar',
		icon: <FaBitcoin />,
		desc1: 'Você ganha sempre que suas referências ganham ou compram anúncios',
		desc2: 'Materiais promocionais úteis',
		desc3: 'Gerencie suas referências facilmente',
		desc4: 'ganhos vitalícios',
	    },
	    {
		title: 'Código de presente',
		icon: <FaBitcoin />,
		desc1: 'Digite seu código de presente para receber sua recompensa instantaneamente',
		desc2: "Siga as redes sociais do iceFaucet ou seja um usuário ativo para obter os códigos",
		desc3: '',
		desc4: '',
	    },
	    {
		title: 'estaca',
		icon: <FaBitcoin />,
		desc1: 'Obtenha lucro alugando geladeiras para manter suas Icecoins lá',
		desc2: 'Reivindique seu lucro sempre que quiser',
		desc3: 'Divirta-se observando como seu lucro aumenta',
		desc4: 'Aumentar os juros anuais para 20%',
	    },
	    {
		title: 'concurso oferta',
		icon: <FaBitcoin />,
		desc1: 'Concurso inteligente semanal',
		desc2: 'recompensas infinitas',
		desc3: 'Completar mais ofertas gera um prêmio maior e mais vencedores',
		desc4: '',
	    },
	    {
		title: 'concurso links curtos',
		icon: <FaBitcoin />,
		desc1: 'Concurso inteligente semanal',
		desc2: 'recompensas infinitas',
		desc3: 'Completar mais links curtos gera um prêmio maior e mais vencedores',
		desc4: '',
	    },
	    {
		title: 'sistema multinível',
		icon: <FaBitcoin />,
		desc1: 'Aumente o nível do seu status de estaca para obter mais lucro',
		desc2: 'Aumente o status da sua oferta para obter 10% a mais de ganhos',
		desc3: 'Aumente o nível do seu status de link curto para obter 20% mais ganhos e receber recompensas',
		desc4: 'Conviértete en asistente para ayudar a otros usuarios y ganar más',
	    }
        ]
    },
    {
        lang: 'fr',
        info: [
	    {
		title: 'bonus quotidien',
		icon: <FaBitcoin />,
		desc1: 'Remplissez les conditions pour obtenir votre roue porte-bonheur en bonus quotidien',
		desc2: 'Faites tourner la roue de la chance quand vous le souhaitez',
		desc3: 'Augmentez le nombre de roues porte-bonheur en mettant à niveau votre compte',
		desc4: '',
	    },
	    {
		title: 'faucet',
		icon: <FaBitcoin />,
		desc1: 'Gagnez chaque heure',
		desc2: "Les jours de glace, réclamez toutes les 10 minutes",
		desc3: "Réclamez jusqu'à 30X",
		desc4: 'Illimité',
	    },
	    {
		title: 'offre',
		icon: <FaBitcoin />,
		desc1: 'Répondez à un sondage ou effectuez une tâche pour gagner massivement',
		desc2: 'Taux de conservation élevé sur le marché',
		desc3: 'Maintenez votre offre niveau 5 pour gagner 10% en plus',
		desc4: '',
	    },
	    {
		title: 'regarder des publicités',
		icon: <FaBitcoin />,
		desc1: 'Gagnez en regardant des publicités',
		desc2: "Augmentez vos revenus jusqu'à 2X en mettant à niveau votre compte",
		desc3: 'Augmentez de 1% les revenus de Faucet en complétant toutes les annonces vues',
		desc4: '',
	    },
	    {
		title: 'lien court',
		icon: <FaBitcoin />,
		desc1: 'Gagnez en complétant le lien court',
		desc2: 'Gagnez en fonction du tarif de votre pays',
		desc3: 'Augmentez de 2% les revenus de Faucet en complétant chaque lien court',
		desc4: "Doublez vos gains en mettant à niveau votre compte vers l'abonnement Alpha",
	    },
	    {
		title: 'tâche',
		icon: <FaBitcoin />,
		desc1: 'Gagnez en faisant certaines tâches',
		desc2: 'Effectuez des tâches de manière professionnelle pour doubler vos revenus',
		desc3: "Référez la tâche à quelqu'un qui peut la faire et gagnez 5% des gains de la tâche",
		desc4: 'Système anti-perte de temps',
	    },
	    {
		title: 'loterie',
		icon: <FaBitcoin />,
		desc1: 'Achetez des billets et tentez votre chance à la loterie mensuelle',
		desc2: 'Chaque loterie commence avec une récompense initiale',
		desc3: 'Tous les billets ont la même chance de gagner',
		desc4: 'Chaque utilisateur peut acheter un maximum de 20 billets',
	    },
	    {
		title: 'affilier',
		icon: <FaBitcoin />,
		desc1: 'Vous gagnez chaque fois que vos filleuls gagnent ou achètent des publicités',
		desc2: 'Matériel promotionnel utile',
		desc3: 'Gérez facilement vos références',
		desc4: 'gains à vie',
	    },
	    {
		title: 'code cadeau',
		icon: <FaBitcoin />,
		desc1: 'Entrez votre code cadeau pour recevoir votre récompense instantanément.',
		desc2: "Suivez les réseaux sociaux d'iceFaucet ou soyez un utilisateur actif pour obtenir les codes",
		desc3: '',
		desc4: '',
	    },
	    {
		title: 'miser',
		icon: <FaBitcoin />,
		desc1: 'Faites des profits en louant des réfrigérateurs pour y conserver vos Icecoins',
		desc2: "Réclamez vos bénéfices quand vous le souhaitez",
		desc3: "Profitez de voir comment vos bénéfices augmentent",
		desc4: "Augmenter l'intérêt annuel à 20%",
	    },
	    {
		title: "concours d'offre",
		icon: <FaBitcoin />,
		desc1: 'Concours intelligent hebdomadaire',
		desc2: 'récompenses infinies',
		desc3: "Compléter plus d'offres donne un plus gros prix et plus de gagnants",
		desc4: '',
	    },
	    {
		title: 'concours de liens courts',
		icon: <FaBitcoin />,
		desc1: 'Concours intelligent hebdomadaire',
		desc2: 'récompenses infinies',
		desc3: "Compléter plus de liens courts permet d'obtenir un prix plus important et plus de gagnants",
		desc4: '',
	    },
	    {
		title: 'système à plusieurs niveaux',
		icon: <FaBitcoin />,
		desc1: 'Améliorez votre statut de mise pour obtenir plus de profits',
		desc2: 'Améliorez le statut de votre offre pour obtenir 10% de gains supplémentaires',
		desc3: 'Améliorez votre statut de shortlink pour obtenir 20% de gains en plus et recevoir les récompenses',
		desc4: 'Devenez assistant pour aider les autres utilisateurs et gagner plus',
	    }
        ]
    },
    {
        lang: 'de',
        info: [
            {
		title: 'täglicher Bonus',
		icon: <FaBitcoin />,
		desc1: 'Erfüllen Sie die Anforderungen, um Ihr Glücksrad als täglichen Bonus zu erhalten',
		desc2: 'Drehen Sie das Glücksrad, wann immer Sie möchten',
		desc3: 'Erhöhen Sie die Anzahl der Glücksräder, indem Sie Ihr Konto aktualisieren',
		desc4: '',
	    },
	    {
		title: 'faucet',
		icon: <FaBitcoin />,
		desc1: 'Jede Stunde verdienen',
		desc2: 'An Eistagen erfolgt die Geltendmachung alle 10 Minuten',
		desc3: 'Verdienen Sie bis zu 30X',
		desc4: 'Unbegrenzt',
	    },
	    {
		title: 'Angebot',
		icon: <FaBitcoin />,
		desc1: 'Nehmen Sie an einer Umfrage teil oder erledigen Sie eine Aufgabe, um massiv zu verdienen',
		desc2: 'Hohe Erhaltungsquote auf dem Markt',
		desc3: 'Behalten Sie Ihr Angebot auf Stufe 5, um 10 % mehr zu verdienen',
		desc4: '',
	    },
	    {
		title: 'Anzeigen anschauen',
		icon: <FaBitcoin />,
		desc1: 'Verdienen Sie, indem Sie Anzeigen ansehen',
		desc2: 'Steigern Sie Ihre Einnahmen um das Zweifache, indem Sie Ihr Konto aktualisieren',
		desc3: 'Erhöhen Sie Ihre Faucet-Einnahmen um 1%, indem Sie sich jede Anzeige ansehen',
		desc4: '',
	    },
	    {
		title: 'Kurzlink',
		icon: <FaBitcoin />,
		desc1: 'Verdienen Sie, indem Sie den Kurzlink ausfüllen',
		desc2: 'Verdienen Sie basierend auf dem Tarif Ihres Landes',
		desc3: 'Erhöhen Sie Ihre Faucet-Einnahmen um 2%, indem Sie jeden Kurzlink abschließen',
		desc4: 'Verdoppeln Sie Ihre Einnahmen, indem Sie Ihr Konto auf die Alpha-Mitgliedschaft upgraden',
	    },
	    {
		title: 'Aufgabe',
		icon: <FaBitcoin />,
		desc1: 'Verdienen Sie, indem Sie einige Aufgaben erledigen',
		desc2: 'Erledigen Sie Aufgaben professionell, um Ihr Einkommen zu verdoppeln',
		desc3: 'Überweisen Sie die Aufgabe an jemanden, der sie erledigen kann, und verdienen Sie 5 % des Aufgabenerlöses',
		desc4: 'Anti-Zeitverschwendungssystem',
	    },
	    {
		title: 'Lotterie',
		icon: <FaBitcoin />,
		desc1: 'Kaufen Sie Lose und nutzen Sie Ihre Chance bei der monatlichen Lotterie',
		desc2: 'Jede Lotterie beginnt mit einer Anfangsprämie',
		desc3: 'Alle Tickets haben die gleichen Gewinnchancen',
		desc4: 'Jeder Benutzer kann maximal 20 Tickets kaufen',
	    },
	    {
		title: 'affiliate',
		icon: <FaBitcoin />,
		desc1: 'Sie verdienen immer dann, wenn Ihre Empfehlungen Anzeigen verdienen oder kaufen',
		desc2: 'Nützliche Werbematerialien',
		desc3: 'Verwalten Sie Ihre Empfehlungen einfach',
		desc4: 'Lebenseinkommen',
	    },
	    {
		title: 'Geschenkcode',
		icon: <FaBitcoin />,
		desc1: 'Geben Sie Ihren Geschenkcode ein, um Ihre Prämie sofort zu erhalten',
		desc2: "Folgen Sie den sozialen Medien von iceFaucet oder seien Sie ein aktiver Benutzer, um die Codes zu erhalten",
		desc3: '',
		desc4: '',
	    },
	    {
		title: 'profitieren',
		icon: <FaBitcoin />,
		desc1: 'Verdienen Sie Geld, indem Sie Kühlschränke mieten, um Ihre Icecoins dort aufzubewahren',
		desc2: 'Fordern Sie Ihren Gewinn an, wann immer Sie möchten',
		desc3: 'Genießen Sie die Steigerung Ihres Gewinns',
		desc4: 'Erhöhung der jährlichen Verzinsung auf 20 %',
	    },
	    {
		title: 'Wettbewerb Angebot',
		icon: <FaBitcoin />,
		desc1: 'Wöchentlicher Smart-Wettbewerb',
		desc2: 'Unendliche Belohnungen',
		desc3: 'Wenn Sie mehr Angebote abschließen, erhalten Sie einen größeren Preis und mehr Gewinner',
		desc4: '',
	    },
	    {
		title: 'Wettbewerb Kurzlink',
		icon: <FaBitcoin />,
		desc1: 'Wöchentlicher Smart-Wettbewerb',
		desc2: 'Unendliche Belohnungen',
		desc3: 'Das Vervollständigen von mehr Shortlinks führt zu einem größeren Preis und mehr Gewinnern',
		desc4: '',
	    },
	    {
		title: 'mehrstufiges System',
		icon: <FaBitcoin />,
		desc1: 'Erhöhen Sie Ihren Einsatzstatus, um mehr Gewinn zu erzielen',
		desc2: 'Erhöhen Sie Ihren Angebotsstatus, um 10 % mehr zu verdienen',
		desc3: 'Erhöhen Sie Ihren Shortlink-Status, um 20 % mehr zu verdienen und die Belohnungen zu erhalten',
		desc4: 'Werden Sie Assistent, um anderen Benutzern zu helfen und mehr zu verdienen',
	    }
        ]
    },
    {
        lang: 'it',
        info: [
	     {
		title: 'Bonus giornaliero',
		icon: <FaBitcoin />,
		desc1: 'Completa i requisiti per ottenere la tua ruota fortunata come bonus giornaliero',
		desc2: 'Gira la ruota della fortuna quando vuoi',
		desc3: 'Aumenta il numero di ruote fortunate aggiornando il tuo account',
		desc4: '',
	     },
	     {
		title: 'faucet',
		icon: <FaBitcoin />,
		desc1: 'Guadagna ogni ora',
		desc2: 'Nei giorni di ghiaccio dichiarare ogni 10 minuti',
		desc3: 'Guadagna fino a 30X',
		desc4: "Illimitata",
	     },
	     {
		title: 'offerta',
		icon: <FaBitcoin />,
		desc1: "Partecipa a un sondaggio o svolgi un'attività per guadagnare massicciamente",
		desc2: 'Alto tasso di conservazione nel mercato',
		desc3: 'Mantieni la tua offerta al livello 5 per guadagnare il 10% in più',
		desc4: '',
	     },
	     {
		title: 'visualizza annunci',
		icon: <FaBitcoin />,
		desc1: 'Guadagna guardando gli annunci',
		desc2: 'Aumenta i tuoi guadagni fino a raddoppiarli aggiornando il tuo account',
		desc3: "Aumenta i guadagni di Faucet dell'1% guardando tutti gli annunci di visualizzazione",
		desc4: "",
	     },
	     {
		title: 'link breve',
		icon: <FaBitcoin />,
		desc1: 'Guadagna completando il link breve',
		desc2: 'Guadagna in base alla tariffa del tuo Paese',
		desc3: 'Aumenta i guadagni di Faucet del 2% completando ogni link breve',
		desc4: "Raddoppia i tuoi guadagni aggiornando il tuo account all'abbonamento Alpha",
	     },
	     {
		title: 'compito',
		icon: <FaBitcoin />,
		desc1: 'Guadagna facendo alcune attività',
		desc2: 'Svolgi compiti in modo professionale per raddoppiare i tuoi guadagni',
		desc3: "Segnala l'attività a qualcuno che può farlo e guadagna il 5% del guadagno dell'attività",
		desc4: 'Sistema anti-perdita di tempo',
	     },
	     {
		title: 'lotteria',
		icon: <FaBitcoin />,
		desc1: 'Acquista i biglietti e prova la tua possibilità nella lotteria mensile',
		desc2: 'Ogni lotteria inizia con una ricompensa iniziale',
		desc3: 'Tutti i biglietti hanno le stesse possibilità di vincita',
		desc4: 'Ogni utente potrà acquistare un massimo di 20 biglietti',
	     },
	     {
		title: 'affiliato',
		icon: <FaBitcoin />,
		desc1: 'Guadagni ogni volta che i tuoi referral guadagnano o acquistano annunci',
		desc2: 'Materiale promozionale utile',
		desc3: 'Gestisci facilmente i tuoi referral',
		desc4: 'guadagni a vita',
	     },
	     {
		title: 'codice regalo',
		icon: <FaBitcoin />,
		desc1: 'Inserisci il tuo codice regalo per ricevere subito il tuo premio',
		desc2: "Segui i social media di iceFaucet o sii un utente attivo per ottenere i codici",
		desc3: '',
		desc4: '',
	     },
	     {
		title: 'profitto',
		icon: <FaBitcoin />,
		desc1: 'Guadagna affittando frigoriferi per conservare lì i tuoi Icecoin',
		desc2: "Richiedi il tuo profitto quando vuoi",
		desc3: 'Divertiti a guardare come aumentano i tuoi profitti',
		desc4: "Aumentare l'interesse annuo al 20%",
	     },
	     {
		title: 'concorso offerta',
		icon: <FaBitcoin />,
		desc1: 'Concorso intelligente settimanale',
		desc2: 'ricompense infinite',
		desc3: 'Completando più offerte si ottengono premi più grandi e più vincitori',
		desc4: '',
	     },
	     {
		title: 'concorso link breve',
		icon: <FaBitcoin />,
		desc1: 'Concorso intelligente settimanale',
		desc2: 'ricompense infinite',
		desc3: 'Il completamento di più collegamenti brevi garantisce un premio più grande e più vincitori',
		desc4: '',
	     },
	     {
		title: 'sistema multilivello',
		icon: <FaBitcoin />,
		desc1: 'Aumenta il livello della tua puntata per ottenere maggiori profitti',
		desc2: 'Aumenta il livello della tua offerta per ottenere il 10% in più di guadagno',
		desc3: 'Aumenta di livello il tuo stato di shortlink per ottenere il 20% in più di guadagno e ricevere i premi',
		desc4: 'Diventa un assistente per aiutare altri utenti e guadagnare di più',
	     }
        ]
    }
];

export const howEarnEndData = {
    desc: 'You will be excited when we publish new earning ways. So wait for these, they are coming soon.'
};

export const langHowEarnEndData = [
    {
        lang: 'es',
        info: {
            desc: 'Se emocionará cuando publiquemos nuevas formas de obtener ingresos. Así que espera estos, llegarán pronto.'
        }
    },
    {
        lang: 'ru',
        info: {
            desc: 'Вы будете в восторге, когда мы опубликуем новые способы заработка. Так что ждите их, они скоро будут.'
        }
    },
    {
        lang: 'pt',
        info: {
            desc: 'Você ficará animado quando publicarmos novas formas de ganhar. Então espere por estes, eles estão chegando em breve.'
        }
    },
    {
        lang: 'fr',
        info: {
            desc: 'Vous serez enthousiasmé lorsque nous publierons de nouvelles façons de gagner. Alors attendez-les, ils arrivent bientôt.'
        }
    },
    {
        lang: 'de',
        info: {
            desc: 'Sie werden gespannt sein, wenn wir neue Verdienstmöglichkeiten veröffentlichen. Also warte auf diese, sie kommen bald.'
        }
    },
    {
        lang: 'it',
        info: {
            desc: 'Sarai eccitata quando pubblicheremo nuovi modi di guadagnare. Quindi aspetta questi, arriveranno presto.'
        }
    }
];
export const offerwallsData = {
    desc: 'offerwall providers'
};
export const langOfferwallsData = [
    {
	lang: 'es',
	info: {
	    desc: 'offerwall proveedoras'
	}
    },
    {
	lang: 'ru',
	info: {
	    desc: 'offerwall поставщики'
	}
    },
    {
	lang: 'pt',
	info: {
	    desc: 'offerwall provedores'
	}
    },
    {
	lang: 'fr',
	info: {
	    desc: 'offerwall fournisseuses'
	}
    },
    {
	lang: 'de',
	info: {
	    desc: 'offerwall Anbieter'
	}
    },
    {
	lang: 'it',
	info: {
	    desc: 'offerwall fornitrici'
	}
    }
];
export const gatewayData = {
    desc: 'payment gateways'
};
export const langGatewayData = [
    {
	lang: 'es',
	info: {
	    desc: 'pasarela de pago'
	}
    },
    {
	lang: 'ru',
	info: {
	    desc: 'платежные шлюзы'
	}
    },
    {
	lang: 'pt',
	info: {
	    desc: 'gateways de pagamento'
	}
    },
    {
	lang: 'fr',
	info: {
	    desc: 'Passerelles de paiement'
	}
    },
    {
	lang: 'de',
	info: {
	    desc: 'Zahlungsgateways'
	}
    },
    {
	lang: 'it',
	info: {
	    desc: 'gateway di pagamento'
	}
    }
];
