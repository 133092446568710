export const promotionData = [
    'Referral Links',
    'Banners',
    'Social'
];
export const promotionRefTable = [
    {
	detail: 'PURCHASE ADS'
    },
    {
	detail: '%5'
    },
    {
	detail: 'If your referral pays $10 for advertisements, so you earn 5000 PC immediately.',
    },
    {
	detail: 'OFFER'
    },
    {
	detail: '%5'
    },
    {
	detail: 'If your referral earns 5000 PC, you earn 250 PC immediately.',
    },
    {
	detail: 'TASK'
    },
    {
	detail: '%5'
    },
    {
	detail: 'If your referral earns 5000 PC, you earn 250 PC immediately.',
    },
    {
	detail: 'FAUCET'
    },
    {
	detail: '2 PC'
    },
    {
	detail: 'If your referral claims, you earn 2 PC immediately.',
    },
    {
	detail: 'VIEW ADS | SHORTLINK'
    },
    {
	detail: '2 PC'
    },
    {
	detail: 'If your referral completes a shortlink, you earn 2 PC immediately.',
    },
    {
	detail: 'VIEW ADS'
    },
    {
	detail: '0.5 PC'
    },
    {
	detail: 'If your referral views an advertisement, you earn 0.5 PC immediately.',
    }
];
export const promotionLinksTable = [
    {
        src: require('../images/home.png').default,
        alt: 'home page',
        client: 'both'
    }
];
