import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';

export const WContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    padding: 25px;
`
export const WWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 50px 0;
`
export const TypeWrap = styled.div`
    width: 90%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr;
    padding: 10px 0;
    margin-bottom: 15px;

    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
        grid-row-gap: 30px;
    }
`
export const TypeCard = styled.div`
    background:  ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    border: 1px solid  ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 2px 10px  ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 5px;
    width: 180px;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin: auto;
    transform: ${({ active }) => (active ? 'scale(1.07)' : 'scale(1)')};
`
export const ImageWrap = styled.div`
    width: 90px;
    height: 90px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.75)' : 'rgba(39, 117, 85, 0.75)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;

    @media screen and ${device.lg} {
        width: 80px;
        height: 80px;
    }
`
export const Image = styled.img`
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 5px;
`
export const WContext = styled.div`
    width: 90%;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5);
    box-shadow: 0 2px 5px rgba(39, 117, 85, 0.7);
    border-radius: 2px;
    color: #fff;
    margin: 10px 0;
`
export const WContextItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid rgba(39, 117, 85, 0.5);
    padding: 5px;

    span {
        font-size: clamp(0.6rem, 1vw, 0.7rem);
        letter-spacing: 0.5px;
        word-spacing: 1.5px;
        text-transform: uppercase;
        text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
`
export const CryptoWrap = css`
    background: rgba(39, 117, 85, 0.2);
    border: 1px solid rgba(39, 117, 85, 0.5);
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(39, 117, 85, 0.7);
    width: 75%;
    max-width: 94vw;
    display: grid;
    padding: 10px;
    margin: 15px 0 25px 0;

    @media screen and ${device.lg} {
        grid-template-columns : 1fr 1fr;
        grid-row-gap: 30px;
    }
    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
        grid-row-gap: 30px;
    }
`
export const CryptoFaucetPayWrap = styled.div`
    ${CryptoWrap}
    grid-template-columns : 1fr 1fr;
`
export const CryptoWalletWrap = styled.div`
    ${CryptoWrap}
    grid-template-columns : 1fr 1fr 1fr 1fr;
`
