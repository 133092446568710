import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, TitleWrap } from '../Stack/StackElements';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../ContestOffer/ContestElements';
import { Timer } from '../PtcView/PtcViewElements';
import { LotteryHeaderWrap, LotteryInfo, LotteryContainer, LotteryInfoWrap, LotteryTitle, LotteryDesc } from '../Lottery/LotteryElements';
import { offerUserTableHead } from '../../data/offerUserStatusData';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { RewardShower, BaseReward, ImgWrap, InfinityImage } from '../ContestOffer/ContestElements';
import { offerUserStatus, offerUserStatusSmall, statusData, offerUserStatusTableHead, offerUserStatusTableBody } from '../../data/offerUserStatusData';
import { TinyFormButton } from '../ButtonElements';
import { LevelContainer, LevelWrap, ProgressBarWrap, ProgressBar, Desc1, Desc2, Ring, FilterContainer, FilterWraper, ButtonWrap, ButtonFilter, DisplayNumber, RangeInput } from './OfferUserStatusElements';

const OfferUserStatus = ({ smallVeiw }) => {
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [info, setInfo] = useState();
    const [infoTableFilter, setInfoTableFilter] = useState();
    const [apiError, setApiError] = useState('');
    const [apiError2, setApiError2] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const [statusValue, setStatusValue] = useState(Object.freeze(''));
    const [statusIndex, setStatusIndex] = useState();
    const [earnValue, setEarnValue] = useState(Object.freeze(0));
    const [submited, setSubmited] = useState(false);
    const handleClick = () => {
	setShowHelp(!showHelp);
    };
    const handleFilterClick = (e, index) => {
	const STATUS = e.target.getAttribute('value');
	if (STATUS && statusValue !== STATUS) {
	    setStatusValue(STATUS);
	    setStatusIndex(index);
	};
	if (apiError2) {
	    setInfoTableFilter(undefined);
	    setApiError2('');
	};
    };
    const handleChange = (e) => {
	let number = parseInt(e.target.value)
	if (!isNaN(number)) {
	    setEarnValue(parseInt(number));
	};
	if (apiError2) {
	    setInfoTableFilter(undefined);
	    setApiError2('');
	};
    };
    const fetchSubmitFilterTable = async () => {
	setLoading2(true);
	try {
	    const { data } = await axiosInstance.post('offer/status/filter/', {
		'status': statusValue,
		'pcoin': earnValue
	    });
	    setInfoTableFilter(data);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError2(error.response.data.detail) : setApiError2(error.message);
	};
	setLoading2(false);
    };
    const handleSubmit = (e) => {
	e.preventDefault();
	setSubmited(true);
	fetchSubmitFilterTable();
    };
    useEffect(() => {
	const fetchOfferUserStatus = async () => {
	    try {
		const { data } = await axiosInstance.get('offer/status/');
		setInfo(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchOfferUserStatus();
    }, []);
    const renderer = ({ hours, minutes, seconds }) => {
	return <Timer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    return (
	<>
	    <StackContainer primary={0} style={{ top: '0' }}>
	        <StackWrap primary={0}>
	    	    <TitleWrap primary={0}>
	                <h2>Your Offer Status</h2>
	                <HelpIcon style={{ top: '-5px' }} onClick={() => handleClick()} show={showHelp ? 1 : 0} />
	            </TitleWrap>
	    	    {loading &&
			<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
			    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
			</div>
		    }
	    	    {apiError &&
			<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
			    <MsgText primary={0}>
				{apiError}
			    </MsgText>
			</MsgWrap>
		    }
	    	    {!loading && !apiError && info !== undefined &&
			<>
			    {!showHelp &&
				<LotteryHeaderWrap>
				    <LotteryInfo>
				        <LevelContainer>
				            {!smallVeiw && offerUserStatus.map((item, index) => (
						<LevelWrap key={index}>
						    <Desc1 active={item.level === info.info.level ? 1 : 0}>{item.up}</Desc1>
						    <Ring active={(item.level < info.info.level) || (item.level === info.info.level) ? 1 : 0} />
						    <ProgressBarWrap>
						        <ProgressBar width={item.pc !== undefined && item.pc < info.info.offer_earn && item.level === info.info.level && info.info.level !== 5 ? (String((info.info.offer_earn - item.pc) / (item.next_round - item.pc) * 100)) + '%' : (item.level === info.info.level && info.info.level === 1) || (item.level < info.info.level) || info.info.level === 5 ? '100%' : '0'} />
						    </ProgressBarWrap>
						    <Desc2 active={item.level === info.info.level ? 1 : 0}>{item.down}</Desc2>
						    <Desc2 active={item.level === info.info.level ? 1 : 0}>{item.down2}</Desc2>
						</LevelWrap>
					    ))}
				            {smallVeiw && offerUserStatusSmall.map((item, index) => (
						<LevelWrap key={index}>
						    <Desc1 active={item.level === info.info.level ? 1 : 0}>{item.up}</Desc1>
						    <Ring active={(item.level < info.info.level) || (item.level === info.info.level) ? 1 : 0} />
						    <ProgressBarWrap>
						        <ProgressBar width={item.pc !== undefined && item.pc < info.info.offer_earn && item.level === info.info.level && info.info.level !== 5 ? (String((info.info.offer_earn - item.pc) / (item.next_round - item.pc) * 100)) + '%' : (item.level === info.info.level && info.info.level === 1) || (item.level < info.info.level) || info.info.level === 5 ? '100%' : '0'} />
						    </ProgressBarWrap>
						    <Desc2 active={item.level === info.info.level ? 1 : 0}>{item.down}</Desc2>
						    <Desc2 active={item.level === info.info.level ? 1 : 0}>{item.down2}</Desc2>
						</LevelWrap>
					    ))}
				        </LevelContainer>
				        {info.info.level === 5 &&
					    <ImgWrap style={{ margin: '0 auto 5px auto' }}>
						<InfinityImage src='https://icefaucet.com/media/help/blog/offer_level_5.png' alt='level 5' />
					    </ImgWrap>
					}
				        <RewardShower>
				            <BaseReward>
				                <h2>Total Earnings</h2>
				            </BaseReward>
				            <BaseReward style={{ background: 'rgba(29, 107, 187, 0.35)', width: '50%', height: '100%', borderRadius: '0 10px 10px 0' }}>
				                <h2 style={{ color: '#fff' }}>{info.info.offer_earn} PC</h2>
				            </BaseReward>
					</RewardShower>
				    </LotteryInfo>
				</LotteryHeaderWrap>
			    }
			    {showHelp &&
				<HelpWrap style={{ marginBottom: '10px', flexDirection: 'column' }} show={showHelp ? 1 : 0}>
		 		    <p>
					The Total Earnings(TE) is your total earnings from offer.<br />
					Completing more offers makes your level higher.<br />
					Receiving more chargebacks makes your level downer.<br />
					Earn 10% more in level 5.<br />
					Be aware if you keep receiving chargeback, your account will be terminated permanently.<br />
					Delay times protect you from receiving chargebacks.<br />
					More level, less protection delay time.<br />
					Currently, the protection feature does not work for "TimeWall" and "ٍExcentiv", so complete them more carefully.
				    </p>
				    <Table style={{ margin: '20px auto 0 auto' }}>
					<HeadWrap>
					    <HeadRow>
					        {offerUserStatusTableHead.map((header, index) => (
						    <Header key={index}>
							{header}
						    </Header>
						))}
				            </HeadRow>
					</HeadWrap>
					<BodyWrap>
					    {offerUserStatusTableBody.map((row, index) => (
						<BodyRow key={index}>
						    <Cell>
							{row.TE_required}
						    </Cell>
						    <Cell>
							{row.level}
						    </Cell>
						    <Cell>
							{row.delay1}
						    </Cell>
						    <Cell>
							{row.delay2}
						    </Cell>
						</BodyRow>
					    ))}
				        </BodyWrap>
				    </Table>
				</HelpWrap>
			    }
			    <LotteryHeaderWrap>
				<LotteryInfo>
				    <LotteryContainer>
				        <LotteryInfoWrap>
				            <LotteryTitle>
				                <h2>number complete</h2>
				            </LotteryTitle>
				            <LotteryDesc>
				                <h2>{info.info.number_complete}</h2>
				            </LotteryDesc>
				        </LotteryInfoWrap>
				        <LotteryInfoWrap>
				            <LotteryTitle>
				                <h2>complete earn</h2>
				            </LotteryTitle>
				            <LotteryDesc>
				                <h2>{info.info.complete} PC</h2>
				            </LotteryDesc>
				        </LotteryInfoWrap>
				        <LotteryInfoWrap>
				            <LotteryTitle>
				                <h2>number chargeback</h2>
				            </LotteryTitle>
				            <LotteryDesc>
				                <h2>{info.info.number_chargeback}</h2>
				            </LotteryDesc>
				        </LotteryInfoWrap>
				        <LotteryInfoWrap>
				            <LotteryTitle>
				                <h2>chargeback</h2>
				            </LotteryTitle>
				            <LotteryDesc>
				                <h2>{info.info.chargeback} PC</h2>
				            </LotteryDesc>
				        </LotteryInfoWrap>
				    </LotteryContainer>
				</LotteryInfo>
			    </LotteryHeaderWrap>
			    {info.data.length > 0 &&
				<>
			    	    <form style={{ width: '100%' }} action='#' onSubmit={handleSubmit}>
					<LotteryHeaderWrap>
				    	    <LotteryInfo>
				        	<LotteryTitle style={{ display: 'flex', justifyContent: 'center', borderRadius: '15px' }}>
				            	    <h2>Table Filters</h2>
				        	</LotteryTitle>
				        	<FilterContainer>
				            	    <p>Filter the data of the table based on the "status":</p>
				            	    <FilterWraper>
				                	{statusData.map((i, index) => (
						    	    <ButtonWrap key={index}>
								<ButtonFilter value={i} onClick={(e) => handleFilterClick(e, index)} itemActive={statusIndex === index ? 1 : 0}>{i}</ButtonFilter>
						    	    </ButtonWrap>
							))}
			                    	    </FilterWraper>
				        	</FilterContainer>
				        	<FilterContainer>
				            	    <p>Filter the data of the table based on the "earn" value:</p>
				            	    <RangeInput
			            			type='range'
			            			name='visit'
			            			min='0'
			            			max='30000'
			            			value={earnValue}
			            			onChange={handleChange}
			        	    	    />
				            	    <DisplayNumber>
				                	<h2>
				                    	    {'earn > ' + earnValue}
			            			</h2>
				            	    </DisplayNumber>
				        	</FilterContainer>
				        	<TinyFormButton style={{ width: '200px', marginBottom: '15px' }} type='submit' primary={1} big={0}>
				            	    submit
			    			</TinyFormButton>
				    	    </LotteryInfo>
					</LotteryHeaderWrap>
			    	    </form>
			    	    {!submited && infoTableFilter === undefined &&
					<Table style={{ marginTop: '25px' }}>
				    	    <HeadWrap>
				        	<HeadRow>
				            	    {offerUserTableHead.map((header, index) => (
							<Header key={index}>
						    	    {header}
						    	</Header>
					    	    ))}
				        	</HeadRow>
				    	    </HeadWrap>
				    	    <BodyWrap>
				        	{info.data.map((row, index) => (
					    	    <BodyRow key={index}>
							<Cell>
						    	    {row.offer}
							</Cell>
							<Cell>
						    	    {row.pcoin} PC
							</Cell>
							<Cell>
						    	    {row.complete && !row.pending && <span>complete<br /></span>}
						    	    {!row.complete && !row.pending && <span>chargeback<br /></span>}
						    	    {row.pending && <span>pending<br /></span>}
						    	    {row.pending && row.expire_pending_timer !== undefined && row.expire_pending_days !== undefined && row.expire_pending_timer >= 24 * 3600 && <span>{row.expire_pending_days} days</span>}
						    	    {row.pending && row.expire_pending_timer !== undefined && row.expire_pending_timer < 24 * 3600 &&
						        	<span>
							   	    <Countdown
									date={Date.now() + row.expire_pending_timer * 1000}
									renderer={renderer}
							    	    />
								</span>
						    	    }
							</Cell>
							<Cell>
						    	    {moment(row.created).fromNow()}
							</Cell>
					    	    </BodyRow>
						))}
				    	    </BodyWrap>
					</Table>
			    	    }
				</>
			    }
			    {loading2 &&
				<div style={{ margin: '25px auto' }}>
				    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
				</div>
			    }
			    {!loading2 && apiError2 &&
				<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
				    <MsgText primary={0}>
					{apiError2}
				    </MsgText>
				</MsgWrap>
			    }
			    {!loading2 && submited && !apiError2 && infoTableFilter !== undefined &&
				<Table style={{ marginTop: '25px' }}>
				    <HeadWrap>
					<HeadRow>
					    {offerUserTableHead.map((header, index) => (
						<Header key={index}>
						    {header}
					        </Header>
					    ))}
				        </HeadRow>
				    </HeadWrap>
				    <BodyWrap>
					{infoTableFilter.data.map((row, index) => (
					    <BodyRow key={index}>
						<Cell>
						    {row.offer}
						</Cell>
						<Cell>
						    {row.pcoin} PC
						</Cell>
						<Cell>
						    {row.complete && !row.pending && <span>complete<br /></span>}
						    {!row.complete && !row.pending && <span>chargeback<br /></span>}
						    {row.pending && <span>pending<br /></span>}
						    {row.pending && row.expire_pending_timer !== undefined && row.expire_pending_days !== undefined && row.expire_pending_timer >= 24 * 3600 && <span>{row.expire_pending_days} days</span>}
						    {row.pending && row.expire_pending_timer !== undefined && row.expire_pending_timer < 24 * 3600 &&
						        <span>
							    <Countdown
								date={Date.now() + row.expire_pending_timer * 1000}
								renderer={renderer}
							    />
							</span>
						    }
						</Cell>
						<Cell>
						    {moment(row.created).fromNow()}
						</Cell>
					    </BodyRow>
					))}
				    </BodyWrap>
				</Table>
			    }
			</>
		    }
	        </StackWrap>
	    </StackContainer >
	</>
    )
}
export default OfferUserStatus;
