import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../Stack/StackElements';
import { TypeContainer, TypeWrap, TypeImgWrap, TypeImg, CryptoContainer, CryptoWrapper, CryptoWrap, CryptoImgWrap, CryptoImg, Notice } from './FinancialInfoElements';
import wallet from '../../images/wallet.png';
import { cryptoLogoFaucetpayData, cryptoLogoWalletData } from '../../data/cryptoLogoData';
import { InputWrap, Input } from './PersonalityElements';
import { MsgWrap, MsgText } from '../MessageElements';

const FinancialInfo = ({ info, values, setValues }) => {
    const [faucetpayClick, setFaucetpayClick] = useState(false);
    const [walletClick, setWalletClick] = useState(false);
    const [uniqueError, setUniqueError] = useState('');
    const handleBlurAddress = () => {
        (values.tetherFP.length > 0 || values.bitcoinFP.length > 0) && fetchCheckFaucetpayAddress();
    };
    const fetchCheckFaucetpayAddress = async () => {
        try {
            await axiosInstance.post('settings/address-faucetpay-check/', {
                tetherFP: values.tetherFP,
                bitcoinFP: values.bitcoinFP
            });
        } catch (error) {
            error.response && error.response.data.detail ? setUniqueError(error.response.data.detail) : setUniqueError(error.message);
        };
    };
    const faucetpayHandleClick = () => {
        setWalletClick(false);
        setFaucetpayClick(!faucetpayClick);
    };
    const walletHandleClick = () => {
        setFaucetpayClick(false);
        setWalletClick(!walletClick);
    };
    const handleChange = (e) => {
        let { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setUniqueError('');
    };
    return (
        <StackContainer primary={0}>
            <StackWrap primary={0}>
                <TitleWrap primary={0}>
                    <h2>
                        financial information
                    </h2>
                </TitleWrap>
                <StackDiscWrap>
                    <TypeContainer>
                        <TypeWrap>
                            <TypeImgWrap onClick={faucetpayHandleClick} active={faucetpayClick ? 1 : 0}>
                                <TypeImg src='https://icefaucet.com/media/task/faucetpay.jpg' alt='Faucetpay Logo' />
                            </TypeImgWrap>
                            <TypeImgWrap onClick={walletHandleClick} active={walletClick ? 1 : 0}>
                                <TypeImg src={wallet} alt='Wallet Logo' />
                            </TypeImgWrap>
                        </TypeWrap>
                    </TypeContainer>
                    {faucetpayClick &&
                        <CryptoContainer action='#'>
                            {cryptoLogoFaucetpayData.map((crypto, index) => (
				<CryptoWrapper>
                                    <CryptoWrap key={index}>
                                        <CryptoImgWrap>
                                            <CryptoImg src={crypto.src} alt={crypto.alt} />
                                        </CryptoImgWrap>
                                        <InputWrap>
                                            <Input
                                                style={{ paddingLeft: '15px' }}
                                                type='text'
                                                name={crypto.name}
                                                primary={0}
                                                placeholder={`Enter your faucetpay RECEIVED address for ${crypto.name}`}
                                                onChange={handleChange}
                                                value={crypto.name === 'tetherFP' && info.tether !== undefined ? values.tetherFP
                                                    : crypto.name === 'bitcoinFP' && info.bitcoin !== undefined ? values.bitcoinFP
                                                        : ''}
                                                onBlur={handleBlurAddress}
                                            >
                                            </Input>
                                        </InputWrap>
                                    </CryptoWrap>
				    {crypto.name2 === 'tether' &&
					<Notice>Please note, It is based on the Tron(TRX) network. If your Tether address is not in the Tron network, you will lose your assets.</Notice>
				    }
				</CryptoWrapper>
                            ))}
                        </CryptoContainer>
                    }
                    {walletClick &&
                        <CryptoContainer>
                            {cryptoLogoWalletData.map((crypto, index) => (
				<CryptoWrapper>
                                    <CryptoWrap key={index}>
                                        <CryptoImgWrap>
                                            <CryptoImg src={crypto.src} alt={crypto.alt} />
                                        </CryptoImgWrap>
                                        <InputWrap>
                                            <Input
                                                style={{ paddingLeft: '15px' }}
                                                type='text'
                                                name={crypto.name}
                                                primary={0}
                                                placeholder={`Enter your wallet RECEIVED address for ${crypto.name}`}
                                                onChange={handleChange}
                                                value={crypto.name === 'tether' && info.tether !== undefined ? values.tether
                                                    : crypto.name === 'bitcoin' && info.bitcoin !== undefined ? values.bitcoin
                                                        : crypto.name === 'ethereum' && info.ethereum !== undefined ? values.ethereum
                                                            : crypto.name === 'dogecoin' && info.dogecoin !== undefined ? values.dogecoin
						    	        : crypto.name === 'tron' && info.tron !== undefined ? values.tron
                                                                : ''}
                                            >
                                            </Input>
                                        </InputWrap>
                                    </CryptoWrap>
				    {crypto.name === 'tether' &&
					<Notice>Please note, It is based on the Tron(TRX) network. If your Tether address is not in the Tron network, you will lose your assets.</Notice>
				    }
				</CryptoWrapper>
                            ))}
                        </CryptoContainer>
                    }
                </StackDiscWrap>
                {uniqueError &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {uniqueError}
                        </MsgText>
                    </MsgWrap>
                }
            </StackWrap>
        </StackContainer>
    )
}

export default FinancialInfo;
