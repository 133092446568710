import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { SidebarContainer } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { ModalWrapper } from '../AdsControl/MoreInfoModalElements';
import { ContentWrap, TimePriceWrap, TimeWrap, PriceWrap, TimerDeposit, InstructionWrap, ValueAddressWrap } from '../Deposit/ModalElements';
import { WItemContainer, ItemSubjectWrap, ItemDescWrap, TikImg, Reject } from './ModalElements';
import { Circle } from '../Loading';
import { FormButton } from '../ButtonElements';
import Countdown, { zeroPad } from 'react-countdown';
import { MsgWrap, MsgText } from '../MessageElements';

const Modal = ({ type, crypto, value, name, userAddress, timer, setTimer, isSubmitting, setIsSubmitting }) => {
    let history = useHistory();
    const [price, setPrice] = useState();
    const [complete, setComplete] = useState('');
    const [apiError, setApiError] = useState('');
    const [validateError, setValidateError] = useState('');
    const [clicked, setClicked] = useState(false);
    const cryptoValue = value / price / 1000;
    const cryptoReceived = type === 'W' ? (cryptoValue - (0.05 * cryptoValue)).toFixed(6) : cryptoValue.toFixed(6);
    const fetchNowPaymentPrice = async () => {
        try {
            const { data } = await axiosInstance.post('financial/cash-in/estimate/price/', {
                crypto: crypto
            });
            setPrice(parseFloat(data.price));
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const renderer = ({ minutes, seconds }) => {
        return <TimeWrap red={timer < Date.now() + 2 * 60 * 1000 ? 1 : 0}>
            <TimerDeposit red={timer < Date.now() + 2 * 60 * 1000 ? 1 : 0}>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerDeposit>
        </TimeWrap>;
    };
    const fetchValidateMining = async () => {
        try {
            await axiosInstance.get('financial/cash-out/validate/mining/');
            fetchCashOut();
        } catch (error) {
            error.response && error.response.data.detail ? setValidateError(error.response.data.detail) : setValidateError(error.message);
            setTimeout(() => history.push('/logout'), 1000);
        };
    };
    const fetchCashOut = async () => {
        try {
            await axiosInstance.post('financial/cash-out/done/', {
                'type': type,
                'processor': crypto,
                'icecoin': value,
                'value_processor': cryptoReceived,
                'address': userAddress,
		'name': name
            });
            setComplete(true);
            setTimeout(() => history.push('/history-panel'), 3000);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const handleClick = () => {
        !clicked && fetchValidateMining();
        setClicked(true);
    };
    useEffect(() => {
        if (isSubmitting && price === undefined && apiError.length === 0) {
            fetchNowPaymentPrice();
        };
    });
    return (
        <SidebarContainer isOpen={isSubmitting ? 1 : 0}>
            <ModalWrapper>
                <CloseIconWrap onClick={() => { setIsSubmitting(false); setPrice(undefined); setApiError(''); setClicked(false); setValidateError('') }}>
                    <CloseIcon />
                </CloseIconWrap>
                {price !== undefined &&
                    <ContentWrap style={{ height: '90%' }}>
                        <div style={{ width: '100%' }}>
                            <TimePriceWrap>
                                <Countdown
                                    date={timer}
                                    renderer={renderer}
                                    onComplete={() => {
                                        setTimeout(() => history.go(0), 3000);
                                        setTimer(0);
                                    }}
                                />
                                <PriceWrap>
                                    <h2>1 {crypto === 'usdttrc20' ? 'usdt' : crypto} = {price.toFixed(3)} usdt</h2>
                                </PriceWrap>
                            </TimePriceWrap>
                            <InstructionWrap>
                                <p>
                                    You will receive exactly {cryptoReceived} {crypto === 'usdttrc20' ? 'tether (USDT-TRC20)' : crypto.toUpperCase()} on your wallet address.
                                    Please check out anythings again.
                                </p>
                            </InstructionWrap>
                            <ValueAddressWrap>
                                <WItemContainer style={{ gridTemplateColumns: '1fr 1fr' }}>
                                    <ItemSubjectWrap>
                                        <h3>method</h3>
                                    </ItemSubjectWrap>
                                    <ItemDescWrap>
                                        <h3>{type === 'F' ? 'FaucetPay' : 'Wallet'}</h3>
                                    </ItemDescWrap>
                                </WItemContainer>
                                <WItemContainer>
                                    <ItemSubjectWrap>
                                        <h3>processor</h3>
                                    </ItemSubjectWrap>
                                    <ItemDescWrap>
                                        <h3>{crypto}</h3>
                                    </ItemDescWrap>
                                </WItemContainer>
                                <WItemContainer>
                                    <ItemSubjectWrap>
                                        <h3>amount received</h3>
                                    </ItemSubjectWrap>
                                    <ItemDescWrap>
                                        <h3>{cryptoReceived}</h3>
                                    </ItemDescWrap>
                                </WItemContainer>
                                <WItemContainer>
                                    <ItemSubjectWrap>
                                        <h3>your received address</h3>
                                    </ItemSubjectWrap>
                                    <ItemDescWrap>
                                        <h3>{userAddress}</h3>
                                    </ItemDescWrap>
                                </WItemContainer>
                            </ValueAddressWrap>
                        </div>
                        {clicked &&
                            <MsgWrap style={{ flexDirection: 'row' }} primary={!complete && validateError.length !== 0 ? 0 : 1}>
                                <MsgText primary={!complete && validateError.length !== 0 ? 0 : 1}>
                                    Validating your withdrawal request:
                                </MsgText>
                                <MsgText style={{ marginLeft: '30px' }} primary={1}>
                                    {!complete && validateError.length !== 0 ? <Reject><span>X</span></Reject> : !complete ? <Circle /> : <TikImg />}
                                </MsgText>
                            </MsgWrap>
                        }
                        {apiError.length === 0 && !complete && timer > 0 &&
                            <FormButton
                                type='button'
                                style={{ width: '80%', maxWidth: '500px' }}
                                big={0}
                                primary={1}
                                onClick={handleClick}
                            >
                                {!clicked ? 'Cash out' : 'Please wait...'}
                            </FormButton>
                        }
                        {apiError &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    {apiError}
                                </MsgText>
                            </MsgWrap>
                        }
                        {timer === 0 &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    Your time is expired. Please try again.
                                </MsgText>
                            </MsgWrap>
                        }
                        {complete && !apiError &&
                            <MsgWrap style={{ width: '80%' }} primary={1}>
                                <MsgText primary={1}>
                                    Your withdrawal was successfully submitted.
                                </MsgText>
                            </MsgWrap>
                        }
                    </ContentWrap>
                }
            </ModalWrapper>
        </SidebarContainer >
    )
}
export default Modal;
