import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { AIContainer } from '../AccountInfo/AccountInfoElements';
import { RefTurboTimer, IcedayTimer, NewWrap, NewItemWrap, NewItemCard, LastCliam, CircleWrap, Title, ResetInfoWrap, WraperItem, WrapItem, SpeedoMeterAICard, HeadWrap, TimingGift, SpeedoMeterWrap, TimingGift2, ContentWrap, MembershipWrap, ImgWrap, RangeContainer, RangeWrap, RangeFill, ValueWrap, Value, MaxValue, ThunderContainer, ThunderWrap, ThunderItem } from './SpeedometerElements';
import ReactSpeedometer from "react-d3-speedometer";
import omega from '../../images/omega.png';
import beta from '../../images/beta.png';
import alpha from '../../images/alpha.png';
import { HelpIcon, HelpWrap } from '../HelpElements';
import Countdown, { zeroPad } from 'react-countdown';
import { TinyFormButton } from '../ButtonElements';
import { Circle } from '../Loading';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from '../Stack/RadialSeparators';
import { MsgWrap, MsgText } from '../MessageElements';
import SeparateRect from '../SeparateRect';
import { BannerFaucetWrap } from '../DesktopBanner/DesktopBannerElements';

const Speedometer = ({ values, numClaim, snow, iceday, icedayExpireTimer, setIcedayExpireTimer, lastIcedayClaim, setLastIcedayClaim, buttonTimer, banner }) => {
    const history = useHistory();
    const [showHelp1, setShowHelp1] = useState(false);
    const [showHelp2, setShowHelp2] = useState(false);
    const [showHelp3, setShowHelp3] = useState(false);
    const [showHelp4, setShowHelp4] = useState(false);
    const [showHelp5, setShowHelp5] = useState(false);
    const [showHelpThunder, setShowHelpThunder] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [thunderLoading, setThunderLoading] = useState(false);
    const [thunderActive, setThunderActive] = useState(false);
    const handleClick1 = () => {
	setShowHelp1(!showHelp1);
	setShowHelp2(false);
	setShowHelp3(false);
	setShowHelp4(false);
	setShowHelp5(false);
	setShowHelpThunder(false);
    };
    const handleClick2 = () => {
	setShowHelp2(!showHelp2);
	setShowHelp1(false);
	setShowHelp3(false);
	setShowHelp4(false);
	setShowHelp5(false);
	setShowHelpThunder(false);
    };
    const handleClick3 = () => {
        setShowHelp3(!showHelp3);
	setShowHelp1(false);
	setShowHelp2(false);
	setShowHelp4(false);
	setShowHelp5(false);
	setShowHelpThunder(false);
    };
    const handleClick4 = () => {
	setShowHelp4(!showHelp4);
	setShowHelp1(false);
	setShowHelp2(false);
	setShowHelp3(false);
	setShowHelp5(false);
	setShowHelpThunder(false);
    };
    const handleClick5 = () => {
	setShowHelp5(!showHelp5);
	setShowHelp1(false);
	setShowHelp2(false);
	setShowHelp3(false);
	setShowHelp4(false);
	setShowHelpThunder(false);
    };
    const handleClickThunder = () => {
	setShowHelpThunder(!showHelpThunder);
	setShowHelp1(false);
	setShowHelp2(false);
	setShowHelp3(false);
	setShowHelp4(false);
	setShowHelp5(false);
    };
    const handleResetClick = async () => {
	setLoading(true);
	await axiosInstance.get('faucet/reset/ref-turbo/');
	setTimeout(() => history.go(0), 1000);
    };
    const handleThunderClick = async () => {
	if (values.thunder > 0) {
	    setThunderLoading(true);
	    try {
		await axiosInstance.get('faucet/thunder/active/');
		setThunderActive(true);
		setTimeout(() => history.go(0), 1000);
	    } catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    };
	    setThunderLoading(false);
	} else {
	    setError('Your thunder balance is 0.');
	};
    };
    const renderer = ({ hours, minutes, seconds }) => {
	return <RefTurboTimer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</RefTurboTimer>;
    };
    const renderer2 = ({ hours, minutes, seconds }) => {
	return <IcedayTimer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</IcedayTimer>;
    };
    const fetchBannerClicked = async (id) => {
	await axiosInstance.post('banner/clicked/', {
	    'id': id
	});
    };
    const handleBannerClicked = (id) => {
	fetchBannerClicked(id);
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
        <AIContainer>
            <WraperItem>
	        <HelpWrap style={{ marginBottom: '20px' }} show={showHelp1 && !showHelp2 && !showHelp3 && !showHelp4 && !showHelp5 ? 1 : 0}>
	            <p>
	                Your income will double forever if you claim 100 days without interruption. <br />
	                If you don't claim for a day, don't worry. Your percentage will not reset.
	            </p>
	        </HelpWrap>
	        <HelpWrap style={{ marginBottom: '20px' }} show={!showHelp1 && showHelp2 && !showHelp3 && !showHelp4 && !showHelp5 ? 1 : 0}>
	            <p>
	                1% is added for watching every "VIEW ADS". <br />
	    		2% is added for completing every "SHORTLINK". <br />
	                After 24 hours of watching each ad, the bonus will be reduced.
	            </p>
	        </HelpWrap>
	        <HelpWrap style={{ marginBottom: '20px', flexDirection: 'column' }} show={!showHelp1 && !showHelp2 && showHelp3 ? 1 : 0}>
	            <p>
	                If 25 Unique IPs visit your referral link, 1% is added. <br />
	                One IP is counted for many members. <br />
	                Exactly one week after the first IP visits of your referral link, the percentage suddenly drops to zero.
	            </p>
	    	    <ResetInfoWrap>
	    		<div style={{ display: 'flex' }}>
	                    <p>
	    		        Reset to zero in:
	    		    </p>
	    		    <p style={{ marginLeft: '3px' }}>
	    			{values.days === 0 && values.seconds === 0 && 'never'}
	    		    	{values.seconds >= 24 * 3600 && `${values.days} days`}
	    		        {values.seconds < 24 * 3600 && values.seconds > 0 && buttonTimer === 0 &&
			    	    <Countdown
		    		        date={Date.now() + values.seconds * 1000}
		    		        renderer={renderer}
		    		    />
	    		        }
	    	            </p>
	    		</div>
	    		<p>
	    		    Visitors number: {values.number}
	    		</p>
	    	    </ResetInfoWrap>
	            {values.seconds > 0 && (!loading
		        ? <TinyFormButton big={0} primary={1} style={{ width: '50%', margin: '10px auto' }} onClick={handleResetClick}>
		            Reset
		        </TinyFormButton>
		        : <Circle style={{ margin: '10px auto' }} />)
	            }
	        </HelpWrap>
	    	<HelpWrap style={{ marginBottom: '20px' }} show={showHelp4 && !showHelp1 && !showHelp2 && !showHelp3 && !showHelp5 ? 1 : 0}>
	            <p>
	                One two three four SNOW! It usually makes your reward double, enjoy it.
	            </p>
	        </HelpWrap>
	        <HelpWrap style={{ marginBottom: '20px' }} show={showHelp5 && !showHelp1 && !showHelp2 && !showHelp3 && !showHelp4 ? 1 : 0}>
	            <p>
	                For every thousand times you claim, you can have an ice day. This is really exciting because you can claim every 10 minutes for 12 hours. Of course, on some special days, the ice day is activated for all members by admin. <br />
	                Your number claim: {numClaim}
	            </p>
	        </HelpWrap>
	        <HelpWrap style={{ marginBottom: '20px' }} show={showHelpThunder && !showHelp1 && !showHelp2 && !showHelp3 && !showHelp4 && !showHelp5 ? 1 : 0}>
	            <p>
	                Spin the lucky wheel to win the thunder. <br />
	                If you have a thunder, press the "ON" button to activate it. <br />
	                By activating a thunder, you can claim 5 times instantly.
	            </p>
	        </HelpWrap>
	    	<NewWrap>
	    	    <NewItemCard activate={snow ? 1 : 0}>
	    		{(!snow) &&
			    <HelpIcon style={{ left: '30px' }} onClick={() => handleClick4()} show={showHelp4 ? 1 : 0} />
			}
	    		<CircleWrap>
	                    <Title activate={snow ? 1 : 0}>snow</Title>
	                </CircleWrap>
	    		{!snow &&
			    <NewItemWrap>
				<CircularProgressbarWithChildren
				    value={numClaim % 5 * 20}
				    maxValue={100}
				    text={0}
				    strokeWidth={6}
				    styles={buildStyles({
					strokeLinecap: "butt",
					textColor: 'rgba(1, 219, 187, 0.7)',
					pathColor: 'rgba(1, 219, 187, 0.7)',
					textSize: '1.2rem'
				    })}
				>
				    <RadialSeparators
				        count={5}
				        style={{
					    background: "#fff",
					    width: "2px",
					    height: `${6}%`
					}}
				    />
			        </CircularProgressbarWithChildren>
			    </NewItemWrap>
		        }
	            </NewItemCard>
	            {!error && !thunderActive &&
			<ThunderContainer active={values.thunder_active > 0 ? 1 : 0}>
			    {!values.thunder_active &&
				<>
				    <ThunderWrap>
					{[...Array(7)].map((x, i) => (
					    <ThunderItem key={i} active={i < values.thunder ? 1 : 0} />
					))}
					<HelpIcon style={{ width: '14px', height: '14px', top: '-10px' }} onClick={() => handleClickThunder()} show={showHelpThunder ? 1 : 0} />
				    </ThunderWrap>
				    {thunderLoading ? <Circle style={{ width: '15px', height: '15px', margin: '10px auto' }} /> :
					<TinyFormButton big={0} primary={1} style={{ margin: 'auto', padding: '3px 20px' }} onClick={handleThunderClick}>
					    on
					</TinyFormButton>
				    }
				</>
			    }
			    {values.thunder_active &&
				<>
				    <h2>left claims:</h2>
				    <SeparateRect currentNumber={5 - values.thunder_stage} maxNumber={5} />
				</>
			    }
			</ThunderContainer>
		    }
	    	    {thunderActive &&
			<MsgWrap primary={1} style={{ width: '77%', margin: 'auto' }}>
			    <MsgText primary={1}>
			        Thunder is activated successfully.
			    </MsgText>
			</MsgWrap>
	    	    }
	    	    {error &&
			<MsgWrap primary={0} style={{ width: '77%', margin: 'auto' }}>
			    <MsgText primary={0}>
			        {error}
		            </MsgText>
			</MsgWrap>
	    	    }
	            <NewItemCard activate={iceday ? 1 : 0}>
	                {!iceday &&
			    <HelpIcon style={{ left: '30px' }} onClick={() => handleClick5()} show={showHelp5 ? 1 : 0} />
		        }
	                {iceday && icedayExpireTimer > 0 && buttonTimer === 0 &&
			    <Countdown
			        date={Date.now() + (icedayExpireTimer - 10) * 1000}
			        renderer={renderer2}
			        onComplete={() => { setIcedayExpireTimer(0); setLastIcedayClaim(true) }}
			    />
		        }
	                {iceday && icedayExpireTimer <= 0 && lastIcedayClaim &&
			    <LastCliam>
			        <h3>last claim</h3>
			    </LastCliam>
		        }
	                <CircleWrap>
	                    <Title activate={iceday ? 1 : 0}>ice day</Title>
	                </CircleWrap>
	    	    	{!iceday &&
			    <NewItemWrap>
				<CircularProgressbarWithChildren
				    value={numClaim % 1000 * 0.1}
				    maxValue={100}
				    text={0}
				    strokeWidth={6}
				    styles={buildStyles({
					strokeLinecap: "butt",
					textColor: 'rgba(1, 219, 187, 0.7)',
					pathColor: 'rgba(1, 219, 187, 0.7)',
					textSize: '1.2rem'
				    })}
				>
				</CircularProgressbarWithChildren>
			    </NewItemWrap>
		        }
	            </NewItemCard>
	        </NewWrap>
                <WrapItem>
                    <SpeedoMeterAICard>
	    		<HeadWrap>
                            <TimingGift>
                                <h3>no reset</h3>
                            </TimingGift>
	    		    <HelpIcon style={{ marginTop: '4px' }} onClick={handleClick1} show={showHelp1 ? 1 : 0} />
	                </HeadWrap>
                        <SpeedoMeterWrap>
                            <ReactSpeedometer
                                fluidWidth={true}
                                minValue={0}
                                maxValue={100}
                                value={values.daily}
                                customSegmentStops={[0, values.daily, 100]}
                                segmentColors={["#01DBBB", "rgba(29, 107, 187, 0.4)"]}
                                ringWidth={10}
                                needleColor='rgba(1, 219, 187, 0.75)'
                                textColor='#01DBBB'
                                needleHeightRatio={0.6}
                                valueTextFontSize='0'
                                labelFontSize='0'
                                needleTransition="easeElastic"
                                needleTransitionDuration={1000}
                            />
                            <span>100</span>
                        </SpeedoMeterWrap>
                        <ContentWrap>
                            <h3>
                                daily turbo:
                            </h3>
                            <span>
                                {values.daily !== 0 ? '+' + values.daily + '%' : values.daily}
                            </span>
                        </ContentWrap>
                    </SpeedoMeterAICard>
                    <SpeedoMeterAICard>
	    		<HeadWrap>
                            <TimingGift2>
                                <h3>24 hours</h3>
                            </TimingGift2>
	    		    <HelpIcon style={{ marginTop: '4px' }} onClick={handleClick2} show={showHelp2 ? 1 : 0} />
	                </HeadWrap>
                        <SpeedoMeterWrap>
                            <ReactSpeedometer
                                fluidWidth={true}
                                minValue={0}
                                maxValue={200}
                                value={values.visits}
                                customSegmentStops={[0, values.visits, 200]}
                                segmentColors={["#01DBBB", "rgba(39, 117, 85, 0.4)"]}
                                ringWidth={10}
                                needleColor='rgb(1, 219, 187, 0.75)'
                                needleHeightRatio={0.6}
                                valueTextFontSize='0'
                                labelFontSize='0'
                                needleTransition="easeElastic"
                                needleTransitionDuration={1000}
                            />
                            <span style={{ color: '#277555' }}>200</span>
                        </SpeedoMeterWrap>
                        <ContentWrap>
                            <h3>
                                view ads turbo:
                            </h3>
                            <span>
                                {values.visits !== 0 ? '+' + values.visits + '%' : values.visits}
                            </span>
                        </ContentWrap>
                    </SpeedoMeterAICard>
                    <SpeedoMeterAICard>
	    		<HeadWrap>
                            <TimingGift>
                                <h3>7 days</h3>
                            </TimingGift>
	    		    <HelpIcon style={{ marginTop: '4px' }} onClick={handleClick3} show={showHelp3 ? 1 : 0} />
	                </HeadWrap>
                        <SpeedoMeterWrap>
                            <ReactSpeedometer
                                fluidWidth={true}
                                minValue={0}
                                maxValue={600}
                                value={values.ref_turbo}
                                customSegmentStops={[0, values.ref_turbo, 600]}
                                segmentColors={["#01DBBB", "rgba(29, 107, 187, 0.4)"]}
                                ringWidth={10}
                                needleColor='rgb(1, 219, 187, 0.75)'
                                textColor='#01DBBB'
                                needleHeightRatio={0.6}
                                valueTextFontSize='0'
                                labelFontSize='0'
                                needleTransition="easeElastic"
                                needleTransitionDuration={1000}
                            />
                            <span>600</span>
                        </SpeedoMeterWrap>
                        <ContentWrap>
                            <h3>
                                ref link turbo:
                            </h3>
                            <span>
                                {values.ref_turbo !== 0 ? '+' + values.ref_turbo + '%' : values.ref_turbo}
                            </span>
                        </ContentWrap>
                    </SpeedoMeterAICard>
                </WrapItem>
	        {banner !== undefined && banner.p1 !== null && banner.plan === 'A' &&
		    <div style={{ display: 'flex', margin: '15px auto 0 auto' }}>
			<BannerFaucetWrap href={banner.p1.url} target='_blank' onClick={() => handleBannerClicked(banner.p1.banner_id)} onContextMenu={handleRightClick}>
			    <img src={banner.p1.banner_img ? banner.p1.banner_img : banner.p1.banner_url ? banner.p1.banner_url : null} alt={banner.p1.title} title={banner.p1.url.split('/')[2]} />
			</BannerFaucetWrap>
		    </div>
		}
	        {banner !== undefined && banner.p1 !== null && banner.plan === 'B' &&
		    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
			<iframe title='cryptocoinsad'
		            src={banner.p1.src}
		            style={{ width: `${banner.p1.width}px`, height: `${banner.p1.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			</iframe>
		    </div>
	        }
                <MembershipWrap>
                    <ImgWrap>
                        <img src={values.membership === 0.75 ? beta : values.membership === 1.0 ? alpha : omega} alt={omega} />
                    </ImgWrap>
                    <RangeContainer>
                        <RangeWrap>
                            <RangeFill membership={values.membership}>
                            </RangeFill>
                            <ValueWrap>
                                <h3>
                                    Membership turbo:
                                </h3>
                                <Value>
                                    {values.membership === 0.75 ? '200%' : values.membership === 1.0 ? '500%' : 0}
                                </Value>
                            </ValueWrap>
                        </RangeWrap>
                        <MaxValue>500</MaxValue>
                    </RangeContainer>
                </MembershipWrap>
            </WraperItem>
        </AIContainer>
    )
}
export default Speedometer;
