import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';

export const FinancialForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const Container = css`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Wrap = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;

    @media screen and ${device.sm} {
        grid-column-gap: 40px;
    }
`
export const ImgWrap = css`
    cursor: pointer;
    width: 90px;
    height: 90px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background:${({ active }) => (active ? 'rgba(39, 117, 85, 0.75)' : 'rgba(29, 107, 187, 0.75)')};
    border: 1px solid ${({ active }) => (active ? 'rgba(39, 117, 85, 0.5)' : 'rgba(29, 107, 187, 0.5)')};
    box-shadow: 0 0 10px ${({ active }) => (active ? 'rgba(39, 117, 85, 0.7)' : 'rgba(29, 107, 187, 0.7)')};
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    transition: 0.3s ease-in-out;
    transform: ${({ active }) => (active ? 'scale(1.10)' : 'scale(1)')};

    @media screen and ${device.sm} {
        width: 75px;
        height: 75px;
    }

    &:hover {
       background: rgba(39, 117, 85, 0.75);
       transition: 0.3s ease-in-out;
    }
`
export const Img = css`
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 5px;
`
export const TypeContainer = styled.div`
    ${Container}
`
export const TypeWrap = styled.div`
    ${Wrap}
`
export const TypeImgWrap = styled.div`
    ${ImgWrap}
`
export const TypeImg = styled.img`
    ${Img}
`
export const CryptoContainer = styled.form`
    ${Container}
    flex-direction: column;
    width: 80%;
    max-width: 768px;
    background: rgba(39, 117, 85, 0.2);
    border: 1px solid rgba(39, 117, 85, 0.5);
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(39, 117, 85, 0.7);
    margin: 15px auto 0 auto;
    padding: 10px;
    @media screen and ${device.sm} {
        width: 100%;
    }
`
export const CryptoWrapper = styled.div`
    ${Container}
    flex-direction: column;
`
export const CryptoWrap = styled.div`
    ${Wrap}
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 7%;
    margin: 5px auto;

    @media screen and ${device.sm} {
        grid-column-gap: 10px;
    }
`
export const CryptoImgWrap = styled.div`
    ${ImgWrap}
    width: 70px;
    height: 70px;
    cursor: default;
    margin: auto;
    background:rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.7);
    @media screen and ${device.sm} {
        width: 50px;
        height: 50px;
    }
`
export const CryptoImg = styled.img`
    ${Img}
`
export const Notice = styled.span`
    color: red;
    font-size: clamp(0.8rem, 2.4vw, 0.95rem);
`
