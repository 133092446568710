import React, { useEffect, useState } from 'react';
import MiningPlay from '../components/MiningPlay';
import axiosInstance from '../axios';
import InvalidToken from '../components/InvalidToken';
import { MessageContainer, MsgItem } from '../components/PtcPlay/PtcPlaylements';
import { MsgText, MsgWrap } from '../components/MessageElements';
import { TinyButton } from '../components/ButtonElements';
import { MotherContainer } from '../components/PtcDetail/PtcDetailElements';

const SurfMiningPlayPage = ({ match }) => {
    const [data, setData] = useState();
    const [invalidToken, setInvalidToken] = useState(false);
    const [error, setError] = useState('');
    const dark = localStorage.getItem('lightness') ? true : false;
    const fetchPtcPlay = async () => {
	try {
	    const { data } = await axiosInstance.get(`mining/play/${match.params.uidb64}/${match.params.token}/`);
	    setData(data.info);
	} catch (error) {
	    error.response && error.response.data.detail && !error.response.data.invalid ? setError(error.response.data.detail) : setError(error.message);
	    error.response.data.invalid && setInvalidToken(true);
	};
    };
    useEffect(() => {
	if (data === undefined && !error && !invalidToken) {
	    fetchPtcPlay();
	};
    });
    return (
	<MotherContainer dark={dark ? 1 : 0}>
	    {data !== undefined && !invalidToken && !error && <MiningPlay data={data} setData={setData} />}
	    {!invalidToken && error &&
		<MessageContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
			        {error}
		            </MsgText>
			</MsgWrap>
		        <TinyButton to={'/surf-mining'} primary={1} big={1}>
			    return
		        </TinyButton>
		    </MsgItem>
		</MessageContainer>
	    }
	    {invalidToken && <InvalidToken />}
	</MotherContainer>
    )
};
export default SurfMiningPlayPage;
