import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { ContentWrap, TableWrap, Table, HeadWrap, HeadRow, Head, BodyWrap, BodyRow, Cell } from './PaymentsElements';
import { Container, Wrapper, Header, LeftWrap, LogoWrapLink, SubjectWrap } from '../TOS/TOSElements';
import { Card, ImgWrap } from '../HelpDesk/HelpDeskElements';
import logo from '../../images/logo.png';
import { TinyFormButton } from '../ButtonElements';
import { headTable, smallHeadTable } from '../../data/paymentsData';
import moment from 'moment';
import { MsgText, MsgWrap } from '../MessageElements';
import { Paginationwrap, PreviosWrap, PreviosIcon, NumberPageWrap, NumberPage, NextWrap, NextIcon } from '../ManageRef/ManageRefElements';
import { Circle } from '../Loading';

const Payments = ({ code, info, smallView }) => {
    const location = useLocation();
    const params = useParams();
    const pageNumber = params.pageNumber ? parseInt(params.pageNumber) : 1;
    const [type, setType] = useState();
    const [indexType, setIndexType] = useState();
    const [payments, setPayments] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const handleTypeClick = (e, index) => {
        const typeTarget = e.target.getAttribute('name');
        if (typeTarget && typeTarget !== type) {
            setType(typeTarget);
            setIndexType(index);
        };
    };
    useEffect(() => {
        const fetchPayments = async () => {
	    setLoading(true);
            try {
                const { data } = await axios.get(`https://icefaucet.com/api/financial/payments/${type}/?page=${pageNumber}`);
                setPayments(data);
            } catch (error) {
                error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            };
	    setLoading(false);
        };
        pageNumber !== undefined && type !== undefined && fetchPayments();
    }, [pageNumber, type]);
    return (
        <Container>
            <Wrapper>
                <Header>
                    <LeftWrap>
                        <LogoWrapLink
                            to={location.pathname === `/payments/${pageNumber}`
                                ? '/'
                                : location.pathname === `/info/ref/${code}/payments/${pageNumber}`
                                    ? `/ref/${code}`
                                    : '#'
                            }
                        >
                            <img src={logo} alt={'logo of icefaucet'} />
                        </LogoWrapLink>
                    </LeftWrap>
                    <SubjectWrap>
                        <h1>{info.subject}</h1>
                    </SubjectWrap>
                </Header>
                <ContentWrap>
                    {info.card.map((card, index) => (
                        <Card primary={index === indexType ? 1 : 0} key={index}>
                            <ImgWrap primary={index !== indexType ? 1 : 0}>
                                <img src={card.img} alt={card.alt} />
                            </ImgWrap>
                            <TinyFormButton type='button' name={card.name} onClick={(e) => handleTypeClick(e, index)} primary={index !== indexType ? 1 : 0} big={false} style={{ width: '90%' }}>{card.button}</TinyFormButton>
                        </Card>
                    ))}
                </ContentWrap>
	    	{loading &&
		    <div style={{ margin: 'auto' }} >
			<Circle style={{ margin: 'auto', width: '75px', height: '75px' }} />
		    </div>
		}
                {!loading && type !== undefined && payments !== undefined && !error && indexType !== undefined &&
                    <TableWrap>
                        <Table>
                            <HeadWrap>
                                <HeadRow>
                                    {!smallView && headTable.map((item, index) => (
                                        <Head key={index}>
                                            {item}
                                        </Head>
                                    ))}
                                    {smallView && smallHeadTable.map((item, index) => (
                                        <Head key={index}>
                                            {item}
                                        </Head>
                                    ))}
                                </HeadRow>
                            </HeadWrap>
                            <BodyWrap>
                                {payments.info.map((row, index) => (
                                    <BodyRow key={pageNumber > 1 && payments.number / ((pageNumber - 1) * 25) < 1 ? payments.number - index : payments.number - index - 25 * (pageNumber - 1)}>
                                        {!smallView &&
                                            <Cell>
						{pageNumber > 1 && payments.number / ((pageNumber - 1) * 25) < 1 ? payments.number - index : payments.number - index - 25 * (pageNumber - 1)}
                                            </Cell>
                                        }
					{!smallView &&
                                            <Cell>
                                                {row.username ? row.username : row.user}
                                            </Cell>
					}
                                        <Cell>
					    {smallView ? row.address.substring(0, 7) + '...' : row.address}
                                        </Cell>
                                        <Cell>
                                            {row.processor === 'usdttrc20' ? 'tether' : row.processor}
                                        </Cell>
                                        <Cell>
                                            {row.icecoin / 1000} USDT
                                        </Cell>
                                        {!smallView &&
                                            <Cell>
                                                {moment(row.created).fromNow()}
                                            </Cell>
                                        }
                                    </BodyRow>
                                ))}
                            </BodyWrap>
                        </Table>
                    </TableWrap>
                }
                {!loading && error &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {error}
                        </MsgText>
                    </MsgWrap>
                }
                {!loading && payments !== undefined && !error && payments.number > 25 &&
                    <Paginationwrap>
                        {pageNumber !== 1 &&
                            <PreviosWrap to={`${pageNumber - 1}`}>
                                <PreviosIcon />
                            </PreviosWrap>
                        }
                        <NumberPageWrap>
                            <NumberPage>
                                {pageNumber}
                            </NumberPage>
                        </NumberPageWrap>
                        {payments.is_next &&
                            <NextWrap to={`${pageNumber + 1}`}>
                                <NextIcon />
                            </NextWrap>
                        }
                    </Paginationwrap>
                }
            </Wrapper>
        </Container>
    )
}
export default Payments;
