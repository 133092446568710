import React, { useState, useEffect } from 'react';
import { Container, Slider, InfoAdsWrap, InfoAdsRow, TextAdsWrap, ImgAdsWrap, Img, IconWrap, BackIcon, IconWrap2, ForwradIcon, SliderNavWrap, SliderSpan } from './InfoAdsElements';

const DeepSlider = ({ adsSlider, number }) => {
    const [current, setCurrent] = useState(0);
    const length = adsSlider.length;
    const [smallVeiw, setSmallView] = useState(false);
    const viewSmallSize = () => {
        if (window.innerWidth < 1023) {
            setSmallView(true);
        } else if (window.innerWidth >= 1023) {
            setSmallView(false);
        };
    };
    useEffect(() => {
        window.addEventListener('resize', viewSmallSize);
        viewSmallSize();
        return () => window.removeEventListener('resize', viewSmallSize);
    });
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };
    if (!Array.isArray(adsSlider) || adsSlider.length <= 0) {
        return null;
    };
    return (
        <Container id='how it works'>
            <IconWrap active={current !== 0 ? 1 : 0} primary={current % 2 === 0 ? 1 : 0} onClick={prevSlide}>
                <BackIcon />
            </IconWrap>
            {adsSlider.map((slide, index) => (
                <Slider key={index} active={index === current ? 1 : 0}>
                    {index === current &&
                        <InfoAdsWrap greenBg={slide.greenBg ? 1 : 0}>
                            <InfoAdsRow>
                                <TextAdsWrap>
                                    <h4>{slide.description}</h4>
                                </TextAdsWrap>
                                <ImgAdsWrap>
                                    <Img src={smallVeiw ? slide.mobile : slide.img} alt={slide.alt} smallVeiw={smallVeiw ? 1 : 0} />
                                </ImgAdsWrap>
                            </InfoAdsRow>
                        </InfoAdsWrap>
                    }
                </Slider>
            ))}
            <IconWrap2 active={current !== length - 1 ? 1 : 0} primary={current % 2 === 0 ? 1 : 0} onClick={nextSlide}>
                <ForwradIcon />
            </IconWrap2>
            <SliderNavWrap >
                {number.map((index) => (
                    <SliderSpan key={index} active={current === index ? 1 : 0}></SliderSpan>
                ))}
            </SliderNavWrap>
        </Container>
    )
}
export default DeepSlider;