import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import ScrollToTop from '../components/ScrollToTop';
import HeroSection from '../components/HeroSection';
import DeepSlider from '../components/DeepSlider';
import Options from '../components/Options';
import AdsTools from '../components/AdsTools';
import AdsCal from '../components/AdsCal';
import { heroAdvertiseData, langHeroAdvertiseData } from '../data/heroSectionData';
import { adsSlider, langAdsSlider } from '../data/adsSlider';
import { adsOptions, langAdsOptions } from '../data/adsOptionsData';
import { adsToolsIntro, adsToolsHead, adsToolsRow, langAdsToolsIntro, langAdsToolsHead, langAdsToolsRow } from '../data/adsToolsData';
import { toolsData, durationData, durationButtons, bannerData, bannerButtons, filtersData, numberVisitsData, numberImpData, langToolsData, langDurationData, langBannerData, langFiltersData, langNumberVisitsData, langNumberImpData, resultData, langResultData, bottomButton, langBottomButton } from '../data/adsCalData';

const AdvertisePage = () => {
    const pageLang = localStorage.getItem('pageLang');
    const [device, setDevice] = useState();
    const [cryptoCoinsAdInfo, setCryptoCoinsAdInfo] = useState();
    const [surfInfo, setSurfInfo] = useState();
    const [bitTrafficInfo, setBitTrafficInfo] = useState();
    const deviceScreenhandler = () => {
        if (window.innerWidth < 880) {
	    setDevice('M');
        } else {
	    setDevice('D');
	};
    };
    useEffect(() => {
	window.addEventListener('resize', deviceScreenhandler);
	deviceScreenhandler();
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    useEffect(() => {
	const fetchCryptoCoinsAdBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/cryptocoinsad/', { 'height_list': height_list });
	    setCryptoCoinsAdInfo(data.info);
	};
	const fetchSurfBanner = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250, 400];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/surfads/', { 'height_list': height_list });
	    setSurfInfo(data.info);
	    if (Object.keys(data.info).length > 0) {
		const ins = document.getElementById('surf1');
		ins.className = 'surfe-be';
		ins.setAttribute('data-sid', data.info.data);
		const script = document.createElement('script');
		script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
		document.body.appendChild(script);
		return new Promise(() => {
		    script.remove();
		});
	    };
	};
	const fetchBitTraffic = async () => {
	    const height_list = device === 'D' ? [90, 60] : [250];
	    const { data } = await axios.post('https://icefaucet.com/api/aadsbanner/bittraffic/', { 'height_list': height_list });
	    setBitTrafficInfo(data.info);
	};
	if (device !== undefined) {
	    fetchCryptoCoinsAdBanner();
	    fetchSurfBanner();
	    fetchBitTraffic();
	};
    }, [device]);
    return (
        <>
	    <Helmet>
	        <meta name="description" content="Promote your business with many tools and filters" />
	        <meta name="keywords" content="crypto ads, advertising business, free ads, free advertising, ads business, ads site, banner ads, video ads" />
	        <title>iceFaucet | advertise your business</title>
	    </Helmet>
            <ScrollToTop />
            <HeroSection heroData={pageLang ? langHeroAdvertiseData.find(data => data.lang === pageLang).info : heroAdvertiseData} />
            <DeepSlider adsSlider={pageLang ? langAdsSlider.find(data => data.lang === pageLang).info : adsSlider} number={[0, 1, 2, 3, 4, 5, 6]} />
            <Options options={pageLang ? langAdsOptions.find(data => data.lang === pageLang).info : adsOptions} extra={true} />
            <AdsTools adsToolsIntro={pageLang ? langAdsToolsIntro.find(data => data.lang === pageLang).info : adsToolsIntro}
                adsToolsHead={pageLang ? langAdsToolsHead.find(data => data.lang === pageLang).info : adsToolsHead}
                adsToolsRow={pageLang ? langAdsToolsRow.find(data => data.lang === pageLang).info : adsToolsRow}
            />
            <AdsCal toolsData={pageLang ? langToolsData.find(data => data.lang === pageLang).info : toolsData}
                durationData={pageLang ? langDurationData.find(data => data.lang === pageLang).info : durationData}
                durationButtons={durationButtons}
                bannerData={pageLang ? langBannerData.find(data => data.lang === pageLang).info : bannerData}
                bannerButtons={bannerButtons}
                filtersData={pageLang ? langFiltersData.find(data => data.lang === pageLang).info : filtersData}
                numberVisitsData={pageLang ? langNumberVisitsData.find(data => data.lang === pageLang).info : numberVisitsData}
                numberImpData={pageLang ? langNumberImpData.find(data => data.lang === pageLang).info : numberImpData}
                resultData={pageLang ? langResultData.find(data => data.lang === pageLang).info : resultData}
                bottomButton={pageLang ? langBottomButton.find(data => data.lang === pageLang).info : bottomButton}
            />
	    {bitTrafficInfo !== undefined && Object.keys(bitTrafficInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <iframe title='bittrafficads'
		        src={bitTrafficInfo.src}
		        style={{ width: `${bitTrafficInfo.width}px`, height: `${bitTrafficInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {cryptoCoinsAdInfo !== undefined && Object.keys(cryptoCoinsAdInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <iframe title='cryptocoinsad'
		        src={cryptoCoinsAdInfo.src}
		        style={{ width: `${cryptoCoinsAdInfo.width}px`, height: `${cryptoCoinsAdInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {surfInfo !== undefined && Object.keys(surfInfo).length > 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <ins id='surf1'></ins>
		</div>
	    }
        </>
    )
}
export default AdvertisePage;
