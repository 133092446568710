export const depositInfo = [
    {
        src: require('../images/nowpayments.png').default,
        alt: 'auto deposit image',
        title: '3rd party API',
        name: 'auto (fast)',
        min: '$25',
        max: '$999',
        limitation: 'once daily',
        type: 'A'
    },
    {
	src: 'https://icefaucet.com/media/task/faucetpay.jpg',
	alt: 'FaucetPay deposit image',
	title: '3rd party API',
	name: 'auto (instant)',
	min: '$1',
	max: '$999',
	limitation: 'once daily',
	type: 'F'
    },
    {
        src: require('../images/wallet.png').default,
        alt: 'manual deposit image',
        title: 'Icefaucet API',
        name: 'manual (slow)',
        min: '$5',
        max: '$999',
        limitation: 'once daily',
        type: 'M'
    }
];
