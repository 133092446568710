import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';
import { DepositTypeWrap, CryptoAutoWrap, CryptoManualWrap } from './DepositElements';
import { WContainer, WWrap, TypeCard, ImageWrap, Image, WContext, WContextItem } from '../Withdraw/WithdrawElements';
import { NumberInputWrap, NumberInput } from '../SetUpPtc/SetUpPtcFilterElements';
import { TinyFormButton, FormButton } from '../ButtonElements';
import { cryptoLogoAutoDepositData, cryptoLogoFPDepositData, cryptoLogoManualDepositData } from '../../data/cryptoLogoData';
import { MsgWrap, MsgText } from '../MessageElements';
import Modal from './Modal';
import moment from 'moment';

const Deposit = ({ info }) => {
    const location = useLocation();
    const [type, setType] = useState(Object.freeze(''));
    const [typeIndex, setTypeIndex] = useState();
    const [apiError, setApiError] = useState('');
    const [errors, setErrors] = useState('');
    const [limitationTime, setLimitationTime] = useState();
    const [cryptoName, setCryptoName] = useState(Object.freeze(''));
    const [crypto, setCrypto] = useState(Object.freeze(''));
    const [cryptoIndex, setCryptoIndex] = useState();
    const [value, setValue] = useState(Object.freeze(''));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [autoStatus, setAutoStatus] = useState(false);
    const [autoMinDeposit, setAutoMinDeposit] = useState();
    const [timer, setTimer] = useState();
    const [depositAddress, setDepositAddress] = useState();
    const [customFP, setCustomFP] = useState('');
    const [fetchCustom, setFetchCustom] = useState(false);
    const coinName = location.pathname === '/deposit/pcoin' ? 'primecoins' : 'adscoins';
    const coin = location.pathname === '/deposit/pcoin' ? 'P' : 'A';
    const handleClick = (e, index) => {
        const typeTarget = e.target.getAttribute('name');
        if (typeTarget && typeTarget !== type) {
            setType(typeTarget);
            setTypeIndex(index);
            setValue(typeTarget === 'M' ? 50000 : typeTarget === 'F' ? 10000 : '');
            fetchLimitationCheck(typeTarget);
            setErrors('');
            setApiError('');
            setCrypto('');
            setCryptoName('');
            setCryptoIndex();
            setIsSubmitting(false);
        };
    };
    const selectCrypto = (e, index) => {
        const cryptoTarget = e.target.getAttribute('value');
        const cryptoNameTarget = e.target.getAttribute('name');
        if (cryptoTarget && cryptoTarget !== crypto && cryptoNameTarget && cryptoNameTarget !== cryptoName) {
            setCrypto(cryptoTarget);
            setCryptoIndex(index);
            setCryptoName(cryptoNameTarget);
            type === 'A' && fetchNowPaymentMinDeposit(cryptoTarget);
        };
        setIsSubmitting(false);
    };
    const handleChange = (e) => {
        let number = parseInt(e.target.value);
        if (!isNaN(number)) {
            setValue(parseInt(number));
        };
        setErrors('');
        setIsSubmitting(false);
    };
    const handleSubmit = () => {
        if (typeof (value) === 'string') {
            setErrors('You must enter the amount of your deposit first.');
        } else if (type === 'M' && value < 50000) {
            setErrors(`The minimum deposit is 50000 ${coinName}.`)
        } else if (type === 'A' && value < 250000) {
            setErrors(`The minimum deposit is 250000 ${coinName}.`)
        } else if (type === 'A' && value >= 250000 && value < autoMinDeposit) {
            setErrors(`For now, The minimum deposit to this cryptocurrency is ${autoMinDeposit} ${coinName}.
            Icefaucet.com is not responsible for this increase in the minimum deposit.`)
        } else if (value > 9990000) {
            setErrors(`The maximum deposit is 9990000 ${coinName}.`)
        } else {
            fetchDepositAddress();
        };
    };
    const fetchLimitationCheck = async (typeTarget) => {
        const coin = location.pathname === '/deposit/pcoin' ? 'P' : 'A';
        try {
            const { data } = await axiosInstance.get(`financial/cash-in/check/${typeTarget}/${coin}/`);
            setAutoStatus(data.status);
	    setLimitationTime(data.detail ? data.detail : undefined);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const fetchNowPaymentMinDeposit = async (cryptoTarget) => {
        try {
            const { data } = await axiosInstance.post('financial/cash-in/estimate/min-deposit/', {
                crypto: cryptoTarget
            });
            let minValue = Math.ceil(data.min_api * data.price) * 10000;
            minValue <= 250000 ? setValue(250000) : setValue(minValue);
            setAutoMinDeposit(minValue);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const fetchDepositAddress = async () => {
        try {
            const { data } = await axiosInstance.post('financial/cash-in/address/', {
                'name': cryptoName,
		'value': value
            });
            setDepositAddress(data.address);
            type !== 'F' && setIsSubmitting(true);
            type !== 'F' && setTimer(Date.now() + 20 * 60 * 1000);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const fetchFPCustom = async () => {
	setFetchCustom(true);
	try {
	    const { data } = await axiosInstance.get('financial/cash-in/faucetpay/custom/');
	    setCustomFP(data.custom);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    useEffect(() => {
	if (type === 'F' && crypto.length > 0 && !fetchCustom && customFP.length === 0 && apiError.length === 0) {
	    fetchFPCustom();
	};
    });
    return (
        <WContainer>
            <WWrap>
                <DepositTypeWrap>
                    {info.map((item, index) => (
                        <TypeCard key={index} primary={1} active={index === typeIndex ? 1 : 0}>
                            <ImageWrap primary={0}>
                                <Image src={item.src} alt={item.alt} title={item.title} />
                            </ImageWrap>
                            <WContext>
                                <WContextItem>
                                    <span>Type:</span>
                                    <span>{item.name}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>min deposit:</span>
                                    <span>{item.min}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>max deposit:</span>
                                    <span>{item.max}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>limitation:</span>
                                    <span>{item.limitation}</span>
                                </WContextItem>
                            </WContext>
                            <TinyFormButton type='button' name={item.type} primary={index === typeIndex ? 1 : 0} onClick={(e) => handleClick(e, index)}>
                                select
                            </TinyFormButton>
                        </TypeCard>
                    ))}
                </DepositTypeWrap>
                {
                    autoStatus && type === 'A' && !limitationTime &&
                    <CryptoAutoWrap>
                        {cryptoLogoAutoDepositData.map((crypto, index) => (
                            <TypeCard key={index} style={{ width: '120px', height: '130px' }} primary={0} active={cryptoIndex === index ? 1 : 0}>
                                <ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
                                    <Image src={crypto.src} alt={crypto.alt} />
                                </ImageWrap>
                                <TinyFormButton primary={cryptoIndex === index ? 1 : 0} name={crypto.name} value={crypto.value} onClick={(e) => selectCrypto(e, index)}>
                                    {crypto.name}
                                </TinyFormButton>
                            </TypeCard>
                        ))}
                    </CryptoAutoWrap>
                }
	        {
		    autoStatus && type === 'F' && !limitationTime &&
		    <CryptoAutoWrap>
			{cryptoLogoFPDepositData.map((crypto, index) => (
			    <TypeCard key={index} style={{ width: '120px', height: '130px' }} primary={0} active={cryptoIndex === index ? 1 : 0}>
				<ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
				    <Image src={crypto.src} alt={crypto.alt} />
				</ImageWrap>
				<TinyFormButton primary={cryptoIndex === index ? 1 : 0} name={crypto.name} value={crypto.value} onClick={(e) => selectCrypto(e, index)}>
				    {crypto.name}
				</TinyFormButton>
			    </TypeCard>
			))}
		    </CryptoAutoWrap>
		}
                {
                    autoStatus && type === 'M' && !limitationTime &&
                    <CryptoManualWrap>
                        {cryptoLogoManualDepositData.map((crypto, index) => (
                            <TypeCard key={index} style={{ width: '120px', height: '130px' }} primary={0} active={cryptoIndex === index ? 1 : 0}>
                                <ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
                                    <Image src={crypto.src} alt={crypto.alt} />
                                </ImageWrap>
                                <TinyFormButton primary={cryptoIndex === index ? 1 : 0} name={crypto.name} value={crypto.value} onClick={(e) => selectCrypto(e, index)}>
                                    {crypto.name}
                                </TinyFormButton>
                            </TypeCard>
                        ))}
                    </CryptoManualWrap>
                }

                {crypto && limitationTime === undefined &&
                    <NumberInputWrap>
                        <NumberInput
                            type='number'
                            value={value}
                            onChange={handleChange}
                            min={type === 'A' && autoMinDeposit <= 250000
                                ? 250000
                                : type === 'A' && autoMinDeposit > 250000
                                    ? autoMinDeposit
				    : type === 'F'
				        ? 10000
                                        : 50000}
                            max={9990000}
                            step={1000}
                        />
                        <TinyFormButton type='button' onClick={() => setValue(9990000)} primary={0} big={0} >
                            max
                        </TinyFormButton>
                    </NumberInputWrap>
                }
                {errors &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {errors}
                        </MsgText>
                    </MsgWrap>
                }
                {apiError &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
                {limitationTime &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            You could deposit again {moment(Date.now() + limitationTime * 1000).fromNow()}.
                        </MsgText>
                    </MsgWrap>
                }
                {crypto.length > 0 && !isSubmitting && !apiError && !limitationTime && !errors && type !== undefined && type !== 'F' &&
                    <FormButton
                        type='button'
                        style={{ width: '80%', maxWidth: '500px', marginBottom: '15px' }}
                        big={0}
                        primary={1}
                        onClick={() => handleSubmit()}
                    >
                        Deposit
                    </FormButton>
                }
	        {customFP.length > 0 &&
		    <form action="https://faucetpay.io/merchant/webscr" target="_blank" method="post">
			{value >= 10000 && value <= 9990000 &&
			    <FormButton type="submit" name="submit" style={{ width: '100%', maxWidth: '500px', marginBottom: '15px' }} big={0} primary={1} onClick={() => handleSubmit()} >Deposit</FormButton>
			}
			<input type="hidden" name="merchant_username" value="icefa" />
			<br />
			<input type="hidden" name="item_description" value="deposit to iceFaucet" />
			<br />
			<input type="hidden" name="amount1" value={value / 10000} />
			<br />
			<input type="hidden" name="currency1" value="USD" />
			<br />
			<input type="hidden" name="currency2" value={crypto === 'usdttrc20' ? 'USDT' : crypto.toUpperCase()} />
			<br />
			<input type="hidden" name="custom" value={customFP} />
			<br />
			<input type="hidden" name="callback_url" value={`https://icefaucet.com/api/financial/cash-in/faucetpay/callback/${coin}/`} />
			<br />
			<input type="hidden" name="success_url" value="https://icefaucet.com/faucetpay/deposit/success" />
			<br />
			<input type="hidden" name="cancle_url" value="https://icefaucet.com/faucetpay/deposit/fail" />
		    </form>
		}
                {
                    !apiError && !errors && type !== 'F' &&
                    <Modal type={type} crypto={crypto} address={depositAddress} value={value} timer={timer} setTimer={setTimer} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
                }
            </WWrap>
        </WContainer >
    )
}
export default Deposit;
