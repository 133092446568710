export const offerUserTableHead = [
    'offer',
    'earn',
    'status',
    'create'
];
export const offerUserStatus = [
    {
	'level': 1,
	'up': 'level 1',
	'next_round': 0,
	'down': '3 days',
	'down2': '21 days'
    },
    {
	'level': 2,
	'up': 'level 2',
	'pc': 0,
	'next_round': 50000,
	'down': '1 day',
	'down2': '14 days'
    },
    {
	'level': 3,
	'up': 'level 3',
	'pc': 50000,
	'next_round': 250000,
	'down': '12 hours',
	'down2': '7 days'
    },
    {
	'level': 4,
	'up': 'level 4',
	'pc': 250000,
	'next_round': 500000,
	'down': '6 hours',
	'down2': '3 days'
    },
    {
	'level': 5,
	'up': 'level 5',
	'pc': 500000,
	'down': '3 hours',
	'down2': '1 day'
    }
];
export const offerUserStatusSmall = [
    {
	'level': 1,
	'up': 'level 1',
	'next_round': 0
    },
    {
	'level': 2,
	'up': '2',
	'pc': 0,
	'next_round': 50000
    },
    {
	'level': 3,
	'up': '3',
	'pc': 50000,
	'next_round': 250000
    },
    {
	'level': 4,
	'up': '4',
	'pc': 250000,
	'next_round': 500000
    },
    {
	'level': 5,
	'up': '5',
	'pc': 500000
    }
];
export const statusData = [
    'complete',
    'pending',
    'chargeback'
];
export const offerUserStatusTableHead = [
    'TE required',
    'level',
    'delay1',
    'delay2'
];
export const offerUserStatusTableBody = [
    {
	'TE_required': 'negative',
	'level': 1,
	'delay1': '3 days',
	'delay2': '21 days',
    },
    {
	'TE_required': 0,
	'level': 2,
	'delay1': '1 day',
	'delay2': '14 days'
    },
    {
	'TE_required': 50000,
	'level': 3,
	'delay1': '12 hours',
	'delay2': '7 days'
    },
    {
	'TE_required': 250000,
	'level': 4,
	'delay1': '6 hours',
	'delay2': '3 days'
    },
    {
	'TE_required': 500000,
	'level': 5,
	'delay1': '3 hours',
	'delay2': '1 day'
    }
];

