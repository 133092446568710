import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import MiningView from '../components/MiningView';
import { MsgText, MsgWrap } from '../components/MessageElements';
import { MsgContainer, MsgItem } from '../components/PtcDetail/PtcDetailElements';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { TinyButton } from '../components/ButtonElements';
import { Circle } from '../components/Loading';

const SurfMiningPage = () => {
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    const [errorDevice, setErrorDevice] = useState('');
    const [boolian, setBoolian] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
    	const fetchPcoinCheck = async () => {
            try {
                const { data } = await axiosInstance.get('mining/pcoin-check/');
		setInfo(data);
            } catch (error) {
                error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
            };
	    setLoading(false);
        };
	fetchPcoinCheck();
    }, []);
    const deviceScreenhandler = () => {
	if (window.innerWidth < 1023 && info !== undefined && info.device === 'D') {
	    setErrorDevice('The window width for desktop devices should be more than 1000px!');
	};
	setBoolian(true);
    };
    useEffect(() => {
	if (info !== undefined && info.device !== undefined) {
	    window.addEventListener('resize', deviceScreenhandler);
	    deviceScreenhandler();
	};
	return () => {
	    window.removeEventListener('resize', deviceScreenhandler);
	};
    });
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
	    {!loading && apiError && !errorDevice &&
		<MsgContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
			        {apiError}
		            </MsgText>
			</MsgWrap>
			<TinyButton to={'/dashboard'} primary={1} big={1}>
			    return
		        </TinyButton>
		    </MsgItem>
		</MsgContainer>
	    }
	    {!loading && !apiError && errorDevice &&
		<MsgContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
			        {errorDevice}
		            </MsgText>
			</MsgWrap>
			<TinyButton to={'/dashboard'} primary={1} big={1}>
			    return
		        </TinyButton>
		    </MsgItem>
		</MsgContainer>
	    }
            {!loading && !apiError && !errorDevice &&
                <StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			        You must have 2000 primecoins to unlock the mining forever.<br />
			        To start mining click on the start button.<br />
			        You receive 1% of your total earnings as a reward in Adscoin. Please use your Adscoin to advertise your links and increase the number of ads.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
            }
            {!loading && !apiError && !errorDevice && boolian &&
		<MiningView info={info} />
	    }
        </>
    )
}
export default SurfMiningPage;
